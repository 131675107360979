/**
 * Created By : Venkat Salagrama
 */

 import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
 import { Validators, FormBuilder, FormGroup } from '@angular/forms';
 import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
 import { NgbDateStruct, NgbDatepickerConfig, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
 import { DatePipe, NumberSymbol } from '@angular/common'

 // Services
 import { ValidationService } from '../../../services/config/config.service';
 import { ReportService } from '../../../services/report/report.service';
 import { routerTransition } from '../../../services/config/config.service';
 import { ApiService } from '../../../services/api/api.service';
 import { KeyValueChanges, KeyValueDiffer, KeyValueDiffers } from '@angular/core';
 import * as moment from 'moment-timezone';

 import * as $ from 'jquery';

 import { ToastrService } from 'ngx-toastr';
 import { APP_SETTINGS } from 'src/app/models/app-settings';

 declare var MediaRecorder: any;

 @Component({
	 selector: 'app-report-add',
	 templateUrl: './report-add.component.html',
	 styleUrls: ['./report-add.component.css'],
	 animations: [routerTransition()],
	 host: { '[@routerTransition]': '' },
	 providers: [DatePipe]
 })

 export class ReportAddComponent implements OnInit {

	closeResult: string;
  	@ViewChild('content', undefined) content;

	 towersList = [];
	 model: NgbDateStruct;

	 displayWebcam = false;

	 conditionLastSubmission: string = "NA";
	 equipmentLastSubmission: string = "NA";

	 conditionWaveBuoy: string = "&nbsp;&nbsp;Source: Gold Coast Wave Buoy";

	 tideHighFirst: string = "";
	 tideHighSecond: string = "";
	 tideLowFirst: string = "";
	 tideLowSecond: string = "";

	 userDisplayName: any;
	 towerid: number;
	 date: Date;
	 latestDate : any;
	 requestJson: any;
	 requestConditionJson: any;
	 requestEquipmentJson: any;

	 private latestDateDiffer: KeyValueDiffer<string, any>;

	 incidentReports = [];
	 incidentReportsCount: number;

	 selectedFiles1: FileList;
	 selectedFiles2: FileList;

	 showIncidentReportButton: boolean;

	 showFormSubmissionLoader: boolean = false;

	 recordCreateTime: string = '';

	 majorFirstAdiResuscitation: boolean = false;

	items = [];
	fileTypeCount : string = "";
	addItem(newItem: string) {
		var filenameExt = newItem.includes("image") ? ".png" : ".mp4";
		var myFile = this.dataURLtoFile(newItem, "MyFile"+filenameExt);
		let list = new DataTransfer();
		list.items.add(myFile);

		if(this.fileTypeCount == "File1") {
			this.selectedFiles1 = list.files;

			var reader = new FileReader();
			reader.onload = function(e) {
				$('#video-upload-wrap-id').hide();
				$('#video-upload-content-id').show();
				$('#video-upload-image-id').show();
				$('#video-upload-video-id').show();

				if(list.files[0].name.includes("mp4")) {
					$('#video-upload-video-id').attr('src', URL.createObjectURL(list.files[0]));
					$('#video-upload-video-id').attr('alt', list.files[0].name);

					$('#video-upload-image-id-f-m').hide();
					$('#video-upload-video-id-f-m').show();
					$('#video-upload-video-id-f-m').attr('src', URL.createObjectURL(list.files[0]));

					$('#video-upload-image-id').hide();
				} else {

					$('#video-upload-image-id').attr('src', URL.createObjectURL(list.files[0]));
					$('#video-upload-image-id').attr('alt', list.files[0].name);

					$('#video-upload-video-id-f-m').hide();
					$('#video-upload-image-id-f-m').show();
					$('#video-upload-image-id-f-m').attr('src', URL.createObjectURL(list.files[0]));

					$('#video-upload-video-id').hide();
				}
				$('#video-upload-content-id').show();

				$('#image-title-id').html(list.files[0].name);
			};
			reader.readAsDataURL(list.files[0]);
		} else {

			this.selectedFiles2 = list.files;

			var reader = new FileReader();
			reader.onload = function(e) {
				$('#video-upload-wrap-id1').hide();

				$('#video-upload-image-id1').show();
				$('#video-upload-video-id1').show();

				if(list.files[0].name.includes("mp4")) {
					$('#video-upload-video-id1').attr('src', URL.createObjectURL(list.files[0]));
					$('#video-upload-video-id1').attr('alt', list.files[0].name);

					$('#video-upload-image-id-f-m-1').hide();
					$('#video-upload-video-id-f-m-1').show();
					$('#video-upload-video-id-f-m-1').attr('src', URL.createObjectURL(list.files[0]));

					$('#video-upload-image-id1').hide();
				} else {

					$('#video-upload-image-id1').attr('src', URL.createObjectURL(list.files[0]));
					$('#video-upload-image-id1').attr('alt', list.files[0].name);

					$('#video-upload-video-id-f-m-1').hide();
					$('#video-upload-image-id-f-m-1').show();
					$('#video-upload-image-id-f-m-1').attr('src', URL.createObjectURL(list.files[0]));

					$('#video-upload-video-id1').hide();
				}

				$('#video-upload-content-id1').show();

				$('#image-title-id1').html(list.files[0].name);
			};
			reader.readAsDataURL(list.files[0]);

		}
		this.displayWebcam = false;
	}

	dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {type:mime});
    }


	 checkoutForm = this.formBuilder.group({
		 roastered_staff: [''],
			 beach_status: [''],
			 swim_9am: [''],
			 swim_12pm: [''],
			 swim_3pm: [''],
			 craft_9am: [''],
			 craft_12pm: [''],
			 craft_3pm: [''],
			 beach_9am: [''],
			 beach_12pm: [''],
			 beach_3pm: [''],
			 board: [''],
			 tube: [''],
			 swim: [''],
			 ski: [''],
			 call_outs: [''],
			 call_outs_after_hours: [''],
			 medical_aid_hospital: [''],
			 medical_aid_doctor_ref: [''],
			 marine_strings: [''],
			 fractures: [''],
			 spinal: [''],
			 dislocation: [''],
			 surf_craft_injury: [''],
			 general: [''],
			 fa_medical_aid_hospital: [''],
			 fa_medical_aid_doctor_ref: [''],
			 theraphy: [''],
			 surf_related_cpr: [''],
			 other_related_cpr: [''],
			 defib: [''],
			 pa: [''],
			 danger_sign: [''],
			 marine_stringer_sign: [''],
			 dog_owner_advised: [''],
			 craft_advised: [''],
			 by_laws_other: [''],
			 public_relations: [''],
			 flag_placement: [''],
			 information_sign: [''],
			 day_notes: [''],

			 surf_conditions: [''],
			 swell_size: [''],
			 weather: [''],
			 wind_strength: [''],
			 wind_direction: [''],
			 beach_width: [''],
			 water_quality: [''],

			 name_on_staff_am: [''],
			 equipment_notes: [''],
			 board_am: [''],
			 tube_am: [''],
			 hand_held_am: [''],
			 vehicle_atv_am: [''],
			 tower_am: [''],
			 pa_tower_am: [''],
			 pa_vehicle_atv_am: [''],
			 first_aid_kit_am: [''],
			 oxgen_am: [''],
			 defib_am: [''],
			 vehicle_check_am: ['']
	 });

	 // create reportAddForm of type FormGroup
	 reportAddForm: FormGroup;
	 index: any;

	 flagPlacementImagePath: string;
	 informationSignImagePath: string;

	 constructor(private formBuilder: FormBuilder, private router: Router, private apiService: ApiService, private route: ActivatedRoute, private reportService: ReportService, private toastr: ToastrService, public datepipe: DatePipe, private differs: KeyValueDiffers, private config: NgbDatepickerConfig, private modalService: NgbModal) {

		/*
		let disableDatesStatus = false;
		let userInfo = localStorage.getItem('userInfo');
		let roles = JSON.parse(userInfo).effectiveRoles;
		const checkRole =  "Admin";
		let adminStatus = roles.findIndex( item =>  checkRole.toLowerCase() === item.toLowerCase())
		// console.log(adminStatus);
		if(adminStatus) {
			const currentDate = new Date();
			config.minDate = {year:currentDate.getFullYear()-5, month:currentDate.getMonth()+1, day: currentDate.getDate()-2};
			config.maxDate = {year:currentDate.getFullYear(), month:currentDate.getMonth()+1, day: currentDate.getDate()};
			config.outsideDays = 'hidden';
		} else {
			const currentDate = new Date();
			config.minDate = {year:currentDate.getFullYear()-5, month:currentDate.getMonth()+1, day: currentDate.getDate()};
			config.maxDate = {year:currentDate.getFullYear(), month:currentDate.getMonth()+1, day: currentDate.getDate()};
			config.outsideDays = 'hidden';
		}
		*/

		const currentDate = new Date();
		config.minDate = {year:currentDate.getFullYear()-5, month:currentDate.getMonth()+1, day: currentDate.getDate()};
		config.maxDate = {year:currentDate.getFullYear(), month:currentDate.getMonth()+1, day: currentDate.getDate()};
		config.outsideDays = 'hidden';

		// Check for route params
		this.route.params.subscribe(params => {
		});

	 }

	 ngOnInit() {


		// this.readAvailableVideoInputs();

		 this.showIncidentReportButton = false;

		 this.towerid = 0;
		 if (typeof(localStorage.getItem('selectedtowerid')) != 'undefined' &&
			 localStorage.getItem('selectedtowerid') != null) {
				 var selectedVal = localStorage.getItem('selectedtowerid');
				 this.towerid = parseInt(selectedVal);
		 } else {
			 this.towerid = 0;
		 }

		 if (typeof(localStorage.getItem('selecteddate')) != 'undefined' &&
			 localStorage.getItem('selecteddate') != null) {

			 this.date=new Date(+JSON.parse(localStorage.getItem('selecteddate')).year,
				 JSON.parse(localStorage.getItem('selecteddate')).month-1,
				 JSON.parse(localStorage.getItem('selecteddate')).day);
			 // this.latestDate = this.datepipe.transform(this.date, 'yyyy-MM-dd');
			 this.latestDate = { day: this.date.getDate(), month: this.date.getMonth()+1, year: this.date.getFullYear() }

		 } else {

			 this.date=new Date();
			 // this.latestDate = this.datepipe.transform(this.date, 'yyyy-MM-dd');
			 this.latestDate = { day: this.date.getDate(), month: this.date.getMonth()+1, year: this.date.getFullYear() }

		 }

		 this.latestDateDiffer = this.differs.find(this.latestDate).create();

		 let userInfo = localStorage.getItem('userInfo');
		 if ((typeof(JSON.parse(userInfo).firstName) != 'undefined' && JSON.parse(userInfo).firstName != null)
			 && (typeof(JSON.parse(userInfo).surname) != 'undefined' && JSON.parse(userInfo).surname != null)) {
			 this.userDisplayName = JSON.parse(userInfo).firstName + " " + JSON.parse(userInfo).surname;
		 } else if (typeof(JSON.parse(userInfo).commonName) != 'undefined' && JSON.parse(userInfo).commonName != null) {
			 this.userDisplayName = JSON.parse(userInfo).commonName;
		 } else {
			 this.userDisplayName = JSON.parse(userInfo).username;
		 }

		 this.checkoutForm = this.formBuilder.group({
			 date : this.date,
			 towerid: this.towerid,
			 towersList: this.towersList,
			 latestDate: this.latestDate,
			 roastered_staff: '',
			 beach_status: "closed",
			 swim_9am: null,
			 swim_12pm: null,
			 swim_3pm: null,
			 craft_9am: null,
			 craft_12pm: null,
			 craft_3pm: null,
			 beach_9am: null,
			 beach_12pm: null,
			 beach_3pm: null,
			 board: 0,
			 tube: 0,
			 swim: 0,
			 ski: 0,
			 call_outs: 0,
			 call_outs_after_hours: 0,
			 medical_aid_hospital: 0,
			 medical_aid_doctor_ref: 0,
			 marine_strings: 0,
			 fractures: 0,
			 spinal: 0,
			 dislocation: 0,
			 surf_craft_injury: 0,
			 general: 0,
			 fa_medical_aid_hospital: 0,
			 fa_medical_aid_doctor_ref: 0,
			 theraphy: 0,
			 surf_related_cpr: 0,
			 other_related_cpr: 0,
			 defib: 0,
			 pa: 0,
			 danger_sign: 0,
			 marine_stringer_sign: 0,
			 dog_owner_advised: 0,
			 craft_advised: 0,
			 by_laws_other: 0,
			 public_relations: 0,
			 flag_placement: null,
			 information_sign: null,
			 day_notes: '',

			 surf_conditions: "",
			 swell_size: 0,
			 weather: "",
			 wind_strength: "",
			 wind_direction: "",
			 beach_width: null,
			 water_quality: "",

			 name_on_staff_am: '',
			 equipment_notes: '',
			 board_am: false,
			 tube_am: false,
			 hand_held_am: false,
			 vehicle_atv_am: false,
			 tower_am: false,
			 pa_tower_am: false,
			 pa_vehicle_atv_am: false,
			 first_aid_kit_am: false,
			 oxgen_am: false,
			 defib_am: false,
			 vehicle_check_am: false
		 });

		 // Fetch Existing data
		 /*var createdDate = this.checkoutForm.value.latestDate.year+"-"+
			 ('0' + this.checkoutForm.value.latestDate.month).slice(-2)+"-"+
			 ('0' + this.checkoutForm.value.latestDate.day).slice(-2)+"T14:00:00.000Z"; */

		var selectedDateTime = this.checkoutForm.value.latestDate.year+"/"+this.checkoutForm.value.latestDate.month+"/"+this.checkoutForm.value.latestDate.day+" 00:00:00";
		var timeToRemove = moment.duration("10:00:00");
		var dateToPass = moment(selectedDateTime);
		dateToPass.subtract(timeToRemove);
		var createdDate = moment(dateToPass).format("yyyy-MM-DDTHH:mm:ss")+".000Z";

		 // Condition Data Load
		 var proceedProcess1 = false;
		 var surf_conditions_value = "";
		 var swell_size_value = null;
		 var weather_value = "";
		 var wind_strength_value = "";
		 var wind_direction_value = "";
		 var beach_width_value = null;
		 var water_quality_value = "";

		 var codataset = localStorage.getItem('codataset');
		 this.conditionLastSubmission = "NA";
		 if (typeof(codataset) != 'undefined' && codataset != null) {

			var createdDate1 = this.checkoutForm.value.latestDate.year+"-"+
				('0' + this.checkoutForm.value.latestDate.month).slice(-2)+"-"+
				('0' + this.checkoutForm.value.latestDate.day).slice(-2)+"T14:00:00.000Z";
				// createdDate to createdDate1 for last load

			 // this.apiService.fetchBeachReport(this.towerid, createdDate, codataset).subscribe(res => {
			 this.apiService.fetchConditionReport(this.towerid, createdDate1, codataset).subscribe(res => {
				 // console.log(JSON.stringify(res));
				 if(res.value.count > 0) {

					 if(res.value.results.length > 0) {
						 /*var datetimesplit = res.value.results[0].timestamp;
						 var currentDateTime = new Date(datetimesplit);
						 let createDateUTCFormat = new Date(Date.UTC(this.checkoutForm.value.latestDate.year, this.checkoutForm.value.latestDate.month-1, this.checkoutForm.value.latestDate.day, currentDateTime.getHours(), currentDateTime.getMinutes(), currentDateTime.getSeconds()));
						 let aestDatetime = createDateUTCFormat.toLocaleTimeString('en-AU', {timeZone: "Australia/Brisbane"});
						 this.conditionLastSubmission = aestDatetime + " today";*/

						 var datetimesplit = res.value.results[0].timestamp;
						 var currentDateTime = new Date(datetimesplit).toUTCString();
						 var aestTimestamp = moment.utc(datetimesplit).tz("Australia/Brisbane").format("DD MMM YYYY HH:mm A");
						 // console.log(aestTimestamp);
						 this.conditionLastSubmission = aestTimestamp + " ";
					 }

					 var recId = res.value.results[0].id.id;
					 // Fields
					 this.apiService.fieldmappingOnBeachReport(codataset).subscribe(resp => {

						 var towerId = this.getValueFromList("Tower Id", resp.value);
						 var surf_conditions = this.getValueFromList("Surf Conditions", resp.value);
						 var swell_size = this.getValueFromList("Swell Size", resp.value);
						 var weather = this.getValueFromList("Weather", resp.value);
						 var wind_strength = this.getValueFromList("Wind Strength", resp.value);
						 var wind_direction = this.getValueFromList("Wind Direction", resp.value);
						 var beach_width = this.getValueFromList("Beach Width", resp.value);
						 var water_quality = this.getValueFromList("Water Quality", resp.value);

						 for(var i=0;i<res.value.results.length;i++) {
							 var rr = res.value.results[i].data;
							 for(var key in rr) {
								 if(towerId === key) {
									 var objectValue = rr[key];

									 var towerIdFromString = this.processTowerIdFromName(this.towerid);

									 // if(objectValue === this.towerid) {
									 if(objectValue === towerIdFromString) {
										 // this.toastr.success('Conditions Record', 'Record is already exists!');

										 surf_conditions_value = rr[surf_conditions];
										 swell_size_value = rr[swell_size];
										 weather_value = rr[weather];
										 wind_strength_value = rr[wind_strength];
										 wind_direction_value = rr[wind_direction];
										 beach_width_value = rr[beach_width];
										 water_quality_value = rr[water_quality];

										 proceedProcess1 = true;
									 }
								 }
							 }
						 }
					 }, (e) => {
						 console.log(e);
					 });
				 }
			 }, (e) => {
				 console.log(e);
			 });
		 } else {
			 this.toastr.error('Failed', 'Please select tower / location !');
			 this.router.navigate(['/']);
		 }

		 // Equipment Data Load
		 var name_on_staff_am_value = '';
		 var equipment_notes_value = '';
		 var board_am_value = false;
		 var tube_am_value = false;
		 var hand_held_am_value = false;
		 var vehicle_atv_am_value = false;
		 var tower_am_value = false;
		 var pa_tower_am_value = false;
		 var pa_vehicle_atv_am_value = false;
		 var first_aid_kit_am_value = false;
		 var oxgen_am_value = false;
		 var defib_am_value = false;
		 var vehicle_check_am_value = false;

		 var eqdataset = localStorage.getItem('eqdataset');
		 this.equipmentLastSubmission = "NA";
		 if (typeof(eqdataset) != 'undefined' && eqdataset != null) {

			var createdDate2 = this.checkoutForm.value.latestDate.year+"-"+
			('0' + this.checkoutForm.value.latestDate.month).slice(-2)+"-"+
			('0' + this.checkoutForm.value.latestDate.day).slice(-2)+"T14:00:00.000Z";
			// createdDate to createdDate2 for last load

			 this.apiService.fetchEquipmentReport(this.towerid, createdDate2, eqdataset).subscribe(res => {
				 // console.log(JSON.stringify(res));
				 if(res.value.count > 0) {

					 if(res.value.results.length > 0) {
						 /*var datetimesplit = res.value.results[0].timestamp;
						 var currentDateTime = new Date(datetimesplit);
						 let createDateUTCFormat = new Date(Date.UTC(this.checkoutForm.value.latestDate.year, this.checkoutForm.value.latestDate.month-1, this.checkoutForm.value.latestDate.day, currentDateTime.getHours(), currentDateTime.getMinutes(), currentDateTime.getSeconds()));
						 let aestDatetime = createDateUTCFormat.toLocaleTimeString('en-AU', {timeZone: "Australia/Brisbane"});
						 this.equipmentLastSubmission = aestDatetime + " today";*/

						var datetimesplit = res.value.results[0].timestamp;
						var currentDateTime = new Date(datetimesplit).toUTCString();
						var aestTimestamp = moment.utc(datetimesplit).tz("Australia/Brisbane").format("DD MMM YYYY HH:mm A");
						// console.log(aestTimestamp);
						this.equipmentLastSubmission = aestTimestamp + " ";
					 }

					 var recId = res.value.results[0].id.id;
					 // Fields
					 this.apiService.fieldmappingOnBeachReport(eqdataset).subscribe(resp => {

						 var name_on_staff_am = this.getValueFromList("Name On Staff am", resp.value);
						 var equipment_notes = this.getValueFromList("Equipment Notes", resp.value);
						 var board_am = this.getValueFromList("Board am", resp.value);
						 var tube_am = this.getValueFromList("Tube am", resp.value);
						 var hand_held_am = this.getValueFromList("Hand Held am", resp.value);
						 var vehicle_atv_am = this.getValueFromList("Vehicle Atv am", resp.value);
						 var tower_am = this.getValueFromList("Tower am", resp.value);
						 var pa_tower_am = this.getValueFromList("Pa Tower am", resp.value);
						 var pa_vehicle_atv_am = this.getValueFromList("Pa Vehicle Atv am", resp.value);
						 var first_aid_kit_am = this.getValueFromList("First Aid Kit am", resp.value);
						 var oxgen_am = this.getValueFromList("Oxgen am", resp.value);
						 var defib_am = this.getValueFromList("Defib am", resp.value);
						 var vehicle_check_am = this.getValueFromList("Vehicle Check am", resp.value);
						 var towerIdeq = this.getValueFromList("Tower Id", resp.value);

						 for(var i=0;i<res.value.results.length;i++) {
							 var rrr = res.value.results[i].data;
							 for(var key in rrr) {
								 if(towerIdeq === key) {
									 var objectValue = rrr[key];

									 var towerIdFromString = this.processTowerIdFromName(this.towerid);

									 // if(objectValue === this.towerid) {
									 if(objectValue === towerIdFromString) {
										 // this.toastr.success('Equipment Record', 'Record is already exists!');

										 name_on_staff_am_value = rrr[name_on_staff_am];
										 equipment_notes_value = rrr[equipment_notes];
										 board_am_value = (rrr[board_am] === 1) ? true : false;
										 tube_am_value = (rrr[tube_am] === 1) ? true : false;
										 hand_held_am_value = (rrr[hand_held_am] === 1) ? true : false;
										 vehicle_atv_am_value = (rrr[vehicle_atv_am] === 1) ? true : false;
										 tower_am_value = (rrr[tower_am] === 1) ? true : false;
										 pa_tower_am_value = (rrr[pa_tower_am] === 1) ? true : false;
										 pa_vehicle_atv_am_value = (rrr[pa_vehicle_atv_am] === 1) ? true : false;
										 first_aid_kit_am_value = (rrr[first_aid_kit_am] === 1) ? true : false;
										 oxgen_am_value = (rrr[oxgen_am] === 1) ? true : false;
										 defib_am_value = (rrr[defib_am] === 1) ? true : false;
										 vehicle_check_am_value = (rrr[vehicle_check_am] === 1) ? true : false;

									 }
								 }
							 }
						 }
					 }, (e) => {
						 console.log(e);
					 });
				 }
			 }, (e) => {
				 console.log(e);
			 });
		 } else {
			 this.toastr.error('Failed', 'Please select tower / location !');
			 this.router.navigate(['/']);
		 }

		 // Beach Report Data Load
		 var brdataset = localStorage.getItem('brdataset');
		 if (typeof(brdataset) != 'undefined' && brdataset != null) {
			 this.apiService.fetchBeachReport(this.towerid, createdDate, brdataset).subscribe(res => {
				 // console.log(JSON.stringify(res));
				 if(res.value.count > 0) {
					 var recId = res.value.results[0].id.id;

					 var files = res.value.results[0].files;

					 this.recordCreateTime = res.value.results[0].createTime;

					 // Fields
					 this.apiService.fieldmappingOnBeachReport(brdataset).subscribe(resp => {

						 var	towerId = this.getValueFromList("Tower Id", resp.value);
						 var	createDate = this.getValueFromList("Create Date", resp.value);
						 var	beach9am = this.getValueFromList("Beach Open to 9am", resp.value);
						 var	beach12pm = this.getValueFromList("Beach 9am to 1pm", resp.value);
						 var	beach3pm = this.getValueFromList("Beach 1pm to Close", resp.value);
						 var	board = this.getValueFromList("Board", resp.value);
						 var	tube = this.getValueFromList("Tube", resp.value);
						 var	swim = this.getValueFromList("Swim", resp.value);
						 var	ski = this.getValueFromList("Ski", resp.value);
						 var	callouts = this.getValueFromList("Call outs", resp.value);
						 var	calloutsAfterHours = this.getValueFromList("Call outs After Hours", resp.value);
						 var	medicalAidHospital = this.getValueFromList("Medical Aid Hospital", resp.value);
						 var	roasteredStaff = this.getValueFromList("Rostered Staff", resp.value);
						 var	medicalAidDoctorRef = this.getValueFromList("Medical Aid Doctor Ref", resp.value);
						 var	marineStrings = this.getValueFromList("Marine Sting", resp.value);
						 var	fractures = this.getValueFromList("Fractures", resp.value);
						 var	spinal = this.getValueFromList("Spinal", resp.value);
						 var	dislocation = this.getValueFromList("Dislocation", resp.value);
						 var	surfCraftInjury = this.getValueFromList("Surf Craft Injury", resp.value);
						 var	general = this.getValueFromList("General", resp.value);
						 var	faMedicalAidHospital = this.getValueFromList("Fa Medical Aid Hospital", resp.value);
						 var	faMedicalAidDoctorRef = this.getValueFromList("Fa Medical Aid Doctor Ref", resp.value);
						 var	theraphy = this.getValueFromList("Therapy", resp.value);
						 var	beachStatus = this.getValueFromList("Beach Status", resp.value);
						 var	surfRelatedCpr = this.getValueFromList("Surf Related Cpr", resp.value);
						 var	otherRelatedCpr = this.getValueFromList("Other Related Cpr", resp.value);
						 var	defib = this.getValueFromList("Defib", resp.value);
						 var	pa = this.getValueFromList("Preventative Actions And Public Announcements", resp.value);
						 var	dangerSign = this.getValueFromList("Danger Sign", resp.value);
						 var	marineStringerSign = this.getValueFromList("Marine Stinger Sign", resp.value);
						 var	dogOwnerAdvised = this.getValueFromList("Dog Owner Advised", resp.value);
						 var	craftAdvised = this.getValueFromList("Craft Advised", resp.value);
						 var	byLawsOther = this.getValueFromList("By Laws Other", resp.value);
						 var	publicRelations = this.getValueFromList("Public Relations", resp.value);
						 var	swim9am = this.getValueFromList("Swim Open to 9am", resp.value);
						 var	flagPlacement = this.getValueFromList("Flag Placement", resp.value);
						 var	informationSign = this.getValueFromList("Information Sign", resp.value);
						 var	dayNotes = this.getValueFromList("Day Notes", resp.value);
						 var	swim12pm = this.getValueFromList("Swim 9am to 1pm", resp.value);
						 var	swim3pm = this.getValueFromList("Swim 1pm to Close", resp.value);
						 var	craft9am = this.getValueFromList("Craft Open to 9am", resp.value);
						 var	craft12pm = this.getValueFromList("Craft 9am to 1pm", resp.value);
						 var	craft3pm = this.getValueFromList("Craft 1pm to Close", resp.value);

						 for(var i=0;i<res.value.results.length;i++) {
							 var r = res.value.results[i].data;
							 for(var key in r) {
								 if(towerId === key) {
									 var objectValue = r[key];

									 var towerIdFromString = this.processTowerIdFromName(this.towerid);

									 // if(objectValue === this.towerid) {
									 if(objectValue === towerIdFromString) {
										 // this.toastr.success('Beach Report Record', 'Record is already exists!');

										 this.checkoutForm = this.formBuilder.group({
											 date : this.date,
											 towerid: this.towerid,
											 towersList: this.towersList,
											 latestDate: this.latestDate,
											 roastered_staff: r[roasteredStaff],
											 beach_status: (r[beachStatus] ===1) ? 'open' : 'closed',
											 swim_9am: r[swim9am],
											 swim_12pm: r[swim12pm],
											 swim_3pm: r[swim3pm],
											 craft_9am: r[craft9am],
											 craft_12pm: r[craft12pm],
											 craft_3pm: r[craft3pm],
											 beach_9am: r[beach9am],
											 beach_12pm: r[beach12pm],
											 beach_3pm: r[beach3pm],
											 board: r[board],
											 tube: r[tube],
											 swim: r[swim],
											 ski: r[ski],
											 call_outs: r[callouts],
											 call_outs_after_hours: r[calloutsAfterHours],
											 medical_aid_hospital: r[medicalAidHospital],
											 medical_aid_doctor_ref: r[medicalAidDoctorRef],
											 marine_strings: r[marineStrings],
											 fractures: r[fractures],
											 spinal: r[spinal],
											 dislocation: r[dislocation],
											 surf_craft_injury: r[surfCraftInjury],
											 general: r[general],
											 fa_medical_aid_hospital: r[faMedicalAidHospital],
											 fa_medical_aid_doctor_ref: r[faMedicalAidDoctorRef],
											 theraphy: r[theraphy],
											 surf_related_cpr: r[surfRelatedCpr],
											 other_related_cpr: r[otherRelatedCpr],
											 defib: r[defib],
											 pa: r[pa],
											 danger_sign: r[dangerSign],
											 marine_stringer_sign: r[marineStringerSign],
											 dog_owner_advised: r[dogOwnerAdvised],
											 craft_advised: r[craftAdvised],
											 by_laws_other: r[byLawsOther],
											 public_relations: r[publicRelations],
											 flag_placement: null,
											 information_sign: null,
											 day_notes: r[dayNotes],

											 surf_conditions: surf_conditions_value,
											 swell_size: swell_size_value,
											 weather: weather_value,
											 wind_strength: wind_strength_value,
											 wind_direction: wind_direction_value,
											 beach_width: beach_width_value,
											 water_quality: water_quality_value,

											 name_on_staff_am: name_on_staff_am_value,
											 equipment_notes: equipment_notes_value,
											 board_am: board_am_value,
											 tube_am: tube_am_value,
											 hand_held_am: hand_held_am_value,
											 vehicle_atv_am: vehicle_atv_am_value,
											 tower_am: tower_am_value,
											 pa_tower_am: pa_tower_am_value,
											 pa_vehicle_atv_am: pa_vehicle_atv_am_value,
											 first_aid_kit_am: first_aid_kit_am_value,
											 oxgen_am: oxgen_am_value,
											 defib_am: defib_am_value,
											 vehicle_check_am: vehicle_check_am_value,

										 });
									 }


									 // This is for Incident Report Alert
									 if (
										(
											(typeof(r[theraphy]) != 'undefined' && r[theraphy] != null && r[theraphy] != 0) ||
											(typeof(r[surfRelatedCpr]) != 'undefined' && r[surfRelatedCpr] != null && r[surfRelatedCpr] != 0) ||
											(typeof(r[otherRelatedCpr]) != 'undefined' && r[otherRelatedCpr] != null && r[otherRelatedCpr] != 0) ||
											(typeof(r[defib]) != 'undefined' && r[defib] != null && r[defib] != 0)
										)
										||
										(
											(typeof(r[fractures]) != 'undefined' && r[fractures] != null && r[fractures] != 0) ||
											(typeof(r[spinal]) != 'undefined' && r[spinal] != null && r[spinal] != 0) ||
											(typeof(r[dislocation]) != 'undefined' && r[dislocation] != null && r[dislocation] != 0) ||
											(typeof(r[faMedicalAidHospital]) != 'undefined' && r[faMedicalAidHospital] != null && r[faMedicalAidHospital] != 0) ||
											(typeof(r[faMedicalAidDoctorRef]) != 'undefined' && r[faMedicalAidDoctorRef] != null && r[faMedicalAidDoctorRef] != 0)
										)
									) {
										this.majorFirstAdiResuscitation = true;

										var irdataset = localStorage.getItem('irdataset');
										if (typeof(irdataset) != 'undefined' && irdataset != null) {
											this.showIncidentReportButton = true;
											this.apiService.fetchIncidentReports(this.towerid, createdDateNewStart, createdDateNewEnd, irdataset).subscribe(res11 => {
												// Open Modal alert if no incident reports
												if(this.majorFirstAdiResuscitation && (res11.value.count == 0 || res11.value.count == undefined)) {
													this.openModal();
												}
											}, (e) => {
												console.log(e);
											});
										} else {
											this.showIncidentReportButton = false;
										}
									}
									// This is for Incident Report Alert
								 }
							 }
						 }


						if(files[flagPlacement].contentType.includes("image")) {
							this.apiService.imageView(brdataset, flagPlacement, recId).subscribe(resp => {
								this.flagPlacementImagePath = 'data:image/png;base64,'+resp;
								$('#video-upload-wrap-id').hide();
								$('#video-upload-image-id').attr('src', 'data:image/png;base64,'+resp);
								$('#video-upload-content-id').show();

							}, (e) => {
								console.log("FlagPlacement File Not Found");
							});
						} else {
							this.apiService.videoView(brdataset, flagPlacement, recId).subscribe(resp => {
								this.flagPlacementImagePath = 'data:video/mp4;base64,'+resp;
								$('#video-upload-wrap-id').hide();
								$('#video-upload-image-id').hide();
								$('#video-upload-video-id').attr('src', 'data:video/mp4;base64,'+resp);
								$('#video-upload-content-id').show();

							}, (e) => {
								console.log("FlagPlacement File Not Found");
							});
						}

						if(files[informationSign].contentType.includes("image")) {
						 this.apiService.imageView(brdataset, informationSign, recId).subscribe(resp => {
							 this.informationSignImagePath = 'data:image/png;base64,'+resp;
							 $('#video-upload-wrap-id1').hide();
							 $('#video-upload-image-id1').attr('src', 'data:image/png;base64,'+resp);
							 $('#video-upload-content-id1').show();
						 }, (e) => {
							 console.log("InformationSign File Not Found");
						 });
						} else {
							this.apiService.videoView(brdataset, informationSign, recId).subscribe(resp => {
								this.informationSignImagePath = 'data:video/mp4;base64,'+resp;
								$('#video-upload-wrap-id1').hide();
								$('#video-upload-image-id1').hide();
								$('#video-upload-video-id1').attr('src', 'data:video/mp4;base64,'+resp);
								$('#video-upload-content-id1').show();

							}, (e) => {
								console.log("InformationSign File Not Found");
							});
						}

					 }, (e) => {
						 console.log(e);
					 });

				 } else {
					this.toastr.info('Information', 'There is no data available for this date.', { timeOut: 5000 });
				 }

			 }, (e) => {
				 console.log(e);
			 });
		 } else {
			 this.toastr.error('Failed', 'Please select tower / location !');
			 this.router.navigate(['/']);
		 }

		 /*var startDate = this.checkoutForm.value.latestDate.year+"-"+
			 ('0' + this.checkoutForm.value.latestDate.month).slice(-2)+"-"+
			 ('0' + this.checkoutForm.value.latestDate.day).slice(-2)+"T00:00:01.000Z";

		 var endDate = this.checkoutForm.value.latestDate.year+"-"+
			 ('0' + this.checkoutForm.value.latestDate.month).slice(-2)+"-"+
			 ('0' + this.checkoutForm.value.latestDate.day).slice(-2)+"T23:59:59.000Z"; */

		// var selectedDateTime = this.checkoutForm.value.latestDate.year+"/"+this.checkoutForm.value.latestDate.month+"/"+this.checkoutForm.value.latestDate.day+" 00:00:00";

		// var selectedDateTime = this.checkoutForm.value.latestDate.year+"/"+this.checkoutForm.value.latestDate.month+"/"+this.checkoutForm.value.latestDate.day+" 00:00:00";
		// var timeToRemove = moment.duration("10:00:00");
		// var dateToPass = moment(selectedDateTime);
		// dateToPass.subtract(timeToRemove);

		// var selectedDateTimeSpecificDate = this.checkoutForm.value.latestDate.year+"/"+this.checkoutForm.value.latestDate.month+"/"+this.checkoutForm.value.latestDate.day+" 00:00:00";
		// var timeToRemoveSpecificHours = moment.duration("10:00:00");
		// var dateToPassSpecific = moment(selectedDateTimeSpecificDate);
		// dateToPassSpecific.subtract(timeToRemoveSpecificHours);
		// var createdDateAsPerZone = moment(dateToPassSpecific).format("yyyy-MM-DDTHH:mm:ss")+".000Z";
		// var createdDateZone = moment.utc(createdDateAsPerZone).tz("Australia/Brisbane").format("yyyy-MM-DD");

		/*var selectedDateTime = this.checkoutForm.value.latestDate.year+"/"+
			('0' + this.checkoutForm.value.latestDate.month).slice(-2)+"/"+
			('0' + this.checkoutForm.value.latestDate.day).slice(-2);
		var dateToPass = moment(selectedDateTime);
		var createdDate = moment(dateToPass).format("yyyy-MM-DD");
		var startDate = createdDate+"T00:00:01.000Z";
		var endDate = createdDate+"T23:59:59.000Z";*/

		// var startDate = createdDateZone+"T00:00:01.000Z";
		// var endDate = createdDateZone+"T23:59:59.000Z";

		var selectedDateTimeNew = this.checkoutForm.value.latestDate.year+"/"+this.checkoutForm.value.latestDate.month+"/"+this.checkoutForm.value.latestDate.day+" 00:00:00";
		var timeToRemoveNew = moment.duration("10:00:00");
		var dateToPassNew = moment(selectedDateTimeNew);
		dateToPassNew.subtract(timeToRemoveNew);
		var createdDateNewStart = moment(dateToPassNew).format("yyyy-MM-DDTHH:mm:ss")+".000Z";
		var createdDateNewEnd = moment.utc(createdDateNewStart).add(moment.duration(24, "hours")).format("yyyy-MM-DDTHH:mm:ss")+".000Z";

		// var startDatetimeZone = moment.utc(startDate).format("yyyy-MM-DDTHH:mm:ss")+".000Z";
		// var endDatetimeZone = moment.utc(endDate).format("yyyy-MM-DDTHH:mm:ss")+".000Z";

		 var irdataset = localStorage.getItem('irdataset');
		 if (typeof(irdataset) != 'undefined' && irdataset != null) {
			 this.showIncidentReportButton = true;
			 this.apiService.fetchIncidentReports(this.towerid, createdDateNewStart, createdDateNewEnd, irdataset).subscribe(res => {
				 if(res.value.count > 0) {
					 this.apiService.fieldmappingOnBeachReport(irdataset).subscribe(resp => {
						 this.incidentReports = res.value.results;
						 this.incidentReportsCount = res.value.results.length;

					 }, (e) => {
						 console.log(e);
					 });
				 }
			 }, (e) => {
				 console.log(e);
			 });
		 } else {
			 this.showIncidentReportButton = false;
		 }

		 this.getTowers();

		 localStorage.removeItem('irdata');

		 this.apiService.fetchTideDetails().subscribe(res => {
			var firstInc = "0";
			var secondInc = "0";
			if(res.value.limit > 0) {
				for(var i=0;i<res.value.results.length;i++) {
					if(res.value.results[i].data.tide_state === "H") {
						if(firstInc === "0") {
							var timestamp = res.value.results[i].timestamp;
							// this.tideHighSecond = moment.utc(timestamp).format("HH:mm A") + " " +res.value.results[i].data.tide_height+"m";

							var aestTimestamp1 = moment.utc(timestamp).tz("Australia/Brisbane").format("HH:mm A");
							this.tideHighSecond = aestTimestamp1 + " " +res.value.results[i].data.tide_height+"m";

							firstInc = "1";
						} else {
							var timestamp = res.value.results[i].timestamp;
							// this.tideHighFirst = moment.utc(timestamp).format("HH:mm A") + " " +res.value.results[i].data.tide_height+"m";

							var aestTimestamp2 = moment.utc(timestamp).tz("Australia/Brisbane").format("HH:mm A");
							this.tideHighFirst = aestTimestamp2 + " " +res.value.results[i].data.tide_height+"m";

						}
					}
					if(res.value.results[i].data.tide_state === "L") {
						if(secondInc === "0") {
							var timestamp = res.value.results[i].timestamp;
							// this.tideLowSecond = moment.utc(timestamp).format("HH:mm A") + " " +res.value.results[i].data.tide_height+"m";

							var aestTimestamp3 = moment.utc(timestamp).tz("Australia/Brisbane").format("HH:mm A");
							this.tideLowSecond = aestTimestamp3 + " " +res.value.results[i].data.tide_height+"m";

							secondInc = "1";
						} else {
							var timestamp = res.value.results[i].timestamp;
							// this.tideLowFirst = moment.utc(timestamp).format("HH:mm A") + " " +res.value.results[i].data.tide_height+"m";

							var aestTimestamp4 = moment.utc(timestamp).tz("Australia/Brisbane").format("HH:mm A");
							this.tideLowFirst = aestTimestamp4 + " " +res.value.results[i].data.tide_height+"m";


						}
					}
				}
			}
		}, (e) => {
			console.log(e);
		});


		this.apiService.fetchWaveBuoyDetails().subscribe(res => {
			// console.log(JSON.stringify(res));
			if(res.value.limit == 1) {
				for(var i=0;i<res.value.results.length;i++) {
					// this.conditionWaveBuoy = "&nbsp;"+res.value.results[i].data.hmax+"m at "+res.value.results[i].data.tp+"sec from "+res.value.results[i].data.pk_dir+"deg. Water "+res.value.results[i].data.sst+" degrees. <br/>&nbsp;Source: Gold Coast Wave Buoy";

					var timestamp = res.value.results[i].timestamp;
					var aestTimestamp = moment.utc(timestamp).tz("Australia/Brisbane").format("HH:mm DD-MM-YYYY");
					// this.conditionWaveBuoy = "&nbsp;"+res.value.results[i].data.hmax+"m at "+res.value.results[i].data.tp+"sec from "+res.value.results[i].data.hsig+"deg. Water "+res.value.results[i].data.sst+" degrees. <br/>&nbsp;Source: Gold Coast Wave Buoy. "+aestTimestamp;
					// this.conditionWaveBuoy = "&nbsp;"+res.value.results[i].data.hsig+"m at "+res.value.results[i].data.tp+"sec from "+res.value.results[i].data.hsig+"deg. Water "+res.value.results[i].data.sst+" degrees. <br/>&nbsp;Source: Gold Coast Wave Buoy. "+aestTimestamp;
					this.conditionWaveBuoy = "&nbsp;"+res.value.results[i].data.hsig+"m at "+res.value.results[i].data.tp+"sec from "+res.value.results[i].data.pk_dir+"deg. Water "+res.value.results[i].data.sst+" degrees. <br/>&nbsp;Source: Gold Coast Wave Buoy. "+aestTimestamp;
				}
			}
		}, (e) => {
			console.log(e);
		});


		 // Back to Top
		 var btn = $('.backtotop');
		 $(window).scroll(function() {
		 if ($(window).scrollTop() > 300) {
			 btn.addClass('show');
		 } else {
			 btn.removeClass('show');
		 }
		 });

		 btn.on('click', function(e) {
			 e.preventDefault();
			 $('html, body').animate({scrollTop:0}, '300');
		 });
		 // Back to Top

	 }

	 getValueFromList(checkValue, dataRecord) {
		 for(var i=0;i<dataRecord.length;i++) {
			 if(dataRecord[i].name === checkValue) {
				 return dataRecord[i].identifier;
			 }
		 }
	 }
	 // Fetch All Towers
	 getTowers() {
		 this.apiService.getTowers().subscribe(res => {

			// console.log(res.value);
			// for(var i=0;i<res.value.length;i++) {
		    // console.log(this.sortAlphaNumeric(res.value, "ASC", "name"));
		   var dataSorted = this.apiService.sortAlphaNumeric(res.value, "ASC", "name");

		   for(var i=0;i<dataSorted.length;i++) {
			 var item = {
				 "id" : dataSorted[i].id,
				 "value" : dataSorted[i].name
			   };
			  this.towersList.push(item);
		   }
			 }, (e) => {
		   console.log(e);
			 });

		 // console.log(this.towersList);
	   }

	 processTowerIdFromName(towerId) {
		 var towerslist = JSON.parse(localStorage.getItem('towers'));
		 var towerIdFromString = "";
		 for(var i=0;i<towerslist.length;i++) {
			 var obj = towerslist[i];
			 if(obj.id === towerId && obj.value.toLowerCase().includes("Tower".toLowerCase())) {
				 var matches = obj.value.match(/(\d+)/);
				 if (matches) {
					 towerIdFromString = matches[0];
				 }
			 }
		 }
		 if(towerIdFromString === "") {
			 towerIdFromString = towerId;
		 }

		 return Number(towerIdFromString);
	 }

	 onBeachReportSubmit(){

		// console.log('Your Beach Report form has been submitted', this.checkoutForm.value);

		var selectedDateTime = this.checkoutForm.value.latestDate.year+"/"+this.checkoutForm.value.latestDate.month+"/"+this.checkoutForm.value.latestDate.day+" 00:00:00";
		var timeToRemove = moment.duration("10:00:00");
		var dateToPass = moment(selectedDateTime);
		dateToPass.subtract(timeToRemove);
		var createdDate = moment(dateToPass).format("yyyy-MM-DDTHH:mm:ss")+".000Z";

		var currentDateTime = new Date();
		// var aestDateTime = this.checkoutForm.value.latestDate.year+"-"+this.checkoutForm.value.latestDate.month+"-"+this.checkoutForm.value.latestDate.day+" "+currentDateTime.getHours()+":"+currentDateTime.getMinutes()+":"+currentDateTime.getSeconds();
		// var aestTimeToRemove = moment.duration("10:00:00");
		// var aestDateToPass = moment(aestDateTime);
		// aestDateToPass.subtract(aestTimeToRemove);
		var aestCreatedDate = moment.utc(currentDateTime).tz("Australia/Brisbane").format("DD/MM/YYYY HH:mm:ss A z");

		// return false;
		// console.log(createdDate);
		// console.log(aestCreatedDate);

		 var towerIdFromString = this.processTowerIdFromName(this.checkoutForm.value.towerid);
		 // "Tower Id": this.checkoutForm.value.towerid,

		 this.requestJson = {
			 "Tower Id": towerIdFromString,
			 "Create Date": aestCreatedDate,
			 "Rostered Staff": this.checkoutForm.value.roastered_staff,
			 "Beach Status": (this.checkoutForm.value.beach_status == "open") ? 1 : 0,
			 "Swim Open to 9am": this.checkoutForm.value.swim_9am,
			 "Swim 9am to 1pm": this.checkoutForm.value.swim_12pm,
			 "Swim 1pm to Close": this.checkoutForm.value.swim_3pm,
			 "Craft Open to 9am": this.checkoutForm.value.craft_9am,
			 "Craft 9am to 1pm": this.checkoutForm.value.craft_12pm,
			 "Craft 1pm to Close": this.checkoutForm.value.craft_3pm,
			 "Beach Open to 9am": this.checkoutForm.value.beach_9am,
			 "Beach 9am to 1pm": this.checkoutForm.value.beach_12pm,
			 "Beach 1pm to Close": this.checkoutForm.value.beach_3pm,
			 "Board": this.checkoutForm.value.board,
			 "Tube": this.checkoutForm.value.tube,
			 "Swim": this.checkoutForm.value.swim,
			 "Ski": this.checkoutForm.value.ski,
			 "Call outs": this.checkoutForm.value.call_outs,
			 "Call outs After Hours": this.checkoutForm.value.call_outs_after_hours,
			 "Medical Aid Hospital": this.checkoutForm.value.medical_aid_hospital,
			 "Medical Aid Doctor Ref": this.checkoutForm.value.medical_aid_doctor_ref,
			 "Marine Sting": this.checkoutForm.value.marine_strings,
			 "Fractures": this.checkoutForm.value.fractures,
			 "Spinal": this.checkoutForm.value.spinal,
			 "Dislocation": this.checkoutForm.value.dislocation,
			 "Surf Craft Injury": this.checkoutForm.value.surf_craft_injury,
			 "General": this.checkoutForm.value.general,
			 "Fa Medical Aid Hospital": this.checkoutForm.value.fa_medical_aid_hospital,
			 "Fa Medical Aid Doctor Ref": this.checkoutForm.value.fa_medical_aid_doctor_ref,
			 "Therapy": this.checkoutForm.value.theraphy,
			 "Surf Related Cpr": this.checkoutForm.value.surf_related_cpr,
			 "Other Related Cpr": this.checkoutForm.value.other_related_cpr,
			 "Defib": this.checkoutForm.value.defib,
			 "Preventative Actions And Public Announcements": this.checkoutForm.value.pa,
			 "Danger Sign": this.checkoutForm.value.danger_sign,
			 "Marine Stinger Sign": this.checkoutForm.value.marine_stringer_sign,
			 "Dog Owner Advised": this.checkoutForm.value.dog_owner_advised,
			 "Craft Advised": this.checkoutForm.value.craft_advised,
			 "By Laws Other": this.checkoutForm.value.by_laws_other,
			 "Public Relations": this.checkoutForm.value.public_relations,
			 "Flag Placement": null,
			 "Information Sign": null,
			 "Day Notes": this.checkoutForm.value.day_notes
		 };

		 const formData: FormData = new FormData();

		 var flagFileStatus = false;
		 var infoFileStatus = false;

		 if (typeof(this.selectedFiles1) != 'undefined' && this.selectedFiles1 != null) {
			 formData.append('file', this.selectedFiles1.item(0));
		 } else {
			 flagFileStatus = true;

			 if (this.flagPlacementImagePath != undefined && typeof(this.flagPlacementImagePath) != 'undefined'
				 && this.flagPlacementImagePath != null) {
					 /*const flagFile1 = new File([
						 new Blob([this.flagPlacementImagePath], {type: 'image/png'})
					 ], "File1.png", {type: 'image/png'});
					 formData.append('file', flagFile1);*/

					 /*var binaryData1 = this.flagPlacementImagePath.split(',')[1];
					 console.log(binaryData1);
					 let flagFile1 = new File([binaryData1], "File1.png",{type:"image/png", lastModified:new Date().getTime()});
					 formData.append('file', flagFile1);	*/

					 // const flagFile1 = new Blob([this.flagPlacementImagePath], {type: 'image/png'});
					 // formData.append('file', flagFile1, 'File1.jpg')

					 if(this.flagPlacementImagePath.includes("image")) {
						var bytes = this.flagPlacementImagePath.split(',')[0].indexOf('base64') >= 0 ? atob(this.flagPlacementImagePath.split(',')[1]) : (<any>window).unescape(this.flagPlacementImagePath.split(',')[1]);
						var mime = this.flagPlacementImagePath.split(',')[0].split(':')[1].split(';')[0];
						var max = bytes.length;
						var ia = new Uint8Array(max);
						for (var i = 0; i < max; i++) {
							ia[i] = bytes.charCodeAt(i);
						}
						var flagFile1 = new File([ia], 'File1.png', { type: mime });
						formData.append('file', flagFile1);
					} else {
						var bytes = this.flagPlacementImagePath.split(',')[0].indexOf('base64') >= 0 ? atob(this.flagPlacementImagePath.split(',')[1]) : (<any>window).unescape(this.flagPlacementImagePath.split(',')[1]);
						var mime = this.flagPlacementImagePath.split(',')[0].split(':')[1].split(';')[0];
						var max = bytes.length;
						var ia = new Uint8Array(max);
						for (var i = 0; i < max; i++) {
							ia[i] = bytes.charCodeAt(i);
						}
						var flagFile1 = new File([ia], 'File1.mp4', { type: mime });
						formData.append('file', flagFile1);
					}
			 } else {
				var dummyFile1 = new File([""], "DummyFile1.png", {type: "image/png"})
				formData.append('file', dummyFile1);
			 }
		 }

		 if (typeof(this.selectedFiles2) != 'undefined' && this.selectedFiles2 != null) {
			 formData.append('file', this.selectedFiles2.item(0));
		 } else {
			 infoFileStatus = true;

			 if (this.informationSignImagePath != undefined && typeof(this.informationSignImagePath) != 'undefined'
				 && this.informationSignImagePath != null) {
					 /*const flagFile2 = new File([
						 new Blob([this.informationSignImagePath], {type: 'image/png'})
					 ], "File2.png", {type: 'image/png'});
					 formData.append('file', flagFile2);*/

					 /*var binaryData2 = this.informationSignImagePath.split(',')[1];
					 let flagFile2 = new File([binaryData2], "File2.png",{type:"image/png", lastModified:new Date().getTime()});
					 formData.append('file', flagFile2);	*/

					 // const flagFile2 = new Blob([this.informationSignImagePath], {type: 'image/png'});
					 // formData.append('file', flagFile2, 'File2.jpg')

					 if(this.informationSignImagePath.includes("image")) {
						var bytes = this.informationSignImagePath.split(',')[0].indexOf('base64') >= 0 ? atob(this.informationSignImagePath.split(',')[1]) : (<any>window).unescape(this.informationSignImagePath.split(',')[1]);
						var mime = this.informationSignImagePath.split(',')[0].split(':')[1].split(';')[0];
						var max = bytes.length;
						var ia = new Uint8Array(max);
						for (var i = 0; i < max; i++) {
							ia[i] = bytes.charCodeAt(i);
						}
						var flagFile2 = new File([ia], 'File2.png', { type: mime });
						formData.append('file', flagFile2);
					} else {
						var bytes = this.informationSignImagePath.split(',')[0].indexOf('base64') >= 0 ? atob(this.informationSignImagePath.split(',')[1]) : (<any>window).unescape(this.informationSignImagePath.split(',')[1]);
						var mime = this.informationSignImagePath.split(',')[0].split(':')[1].split(';')[0];
						var max = bytes.length;
						var ia = new Uint8Array(max);
						for (var i = 0; i < max; i++) {
							ia[i] = bytes.charCodeAt(i);
						}
						var flagFile2 = new File([ia], 'File2.mp4', { type: mime });
						formData.append('file', flagFile2);
					}
			 } else {
				var dummyFile2 = new File([""], "DummyFile2.png", {type: "image/png"})
				formData.append('file', dummyFile2);
			 }
		 }

		 formData.append('jsondata', JSON.stringify(this.requestJson));

		let adminRoleStatus = this.getUserRole();

		if(adminRoleStatus) {

			// AdminRole
			var brdataset = localStorage.getItem('brdataset');
			if (typeof(brdataset) != 'undefined' && brdataset != null) {
				this.showFormSubmissionLoader = true;
				this.apiService.postBeachReport(formData, createdDate, brdataset, 'beachreport').subscribe(res => {
					this.showFormSubmissionLoader = false;
					this.toastr.success('Success', 'Beach Report created successfully!');


					// This is for Incident Report Alert
					if (
						(
							(typeof(this.checkoutForm.value.theraphy) != 'undefined' && this.checkoutForm.value.theraphy != null && this.checkoutForm.value.theraphy != 0) ||
							(typeof(this.checkoutForm.value.surf_related_cpr) != 'undefined' && this.checkoutForm.value.surf_related_cpr != null && this.checkoutForm.value.surf_related_cpr != 0) ||
							(typeof(this.checkoutForm.value.other_related_cpr) != 'undefined' && this.checkoutForm.value.other_related_cpr != null && this.checkoutForm.value.other_related_cpr != 0) ||
							(typeof(this.checkoutForm.value.defib) != 'undefined' && this.checkoutForm.value.defib != null && this.checkoutForm.value.defib != 0)
						)
						||
						(
							(typeof(this.checkoutForm.value.fractures) != 'undefined' && this.checkoutForm.value.fractures != null && this.checkoutForm.value.fractures != 0) ||
							(typeof(this.checkoutForm.value.spinal) != 'undefined' && this.checkoutForm.value.spinal != null && this.checkoutForm.value.spinal != 0) ||
							(typeof(this.checkoutForm.value.dislocation) != 'undefined' && this.checkoutForm.value.dislocation != null && this.checkoutForm.value.dislocation != 0) ||
							(typeof(this.checkoutForm.value.fa_medical_aid_hospital) != 'undefined' && this.checkoutForm.value.fa_medical_aid_hospital != null && this.checkoutForm.value.fa_medical_aid_hospital != 0) ||
							(typeof(this.checkoutForm.value.fa_medical_aid_doctor_ref) != 'undefined' && this.checkoutForm.value.fa_medical_aid_doctor_ref != null && this.checkoutForm.value.fa_medical_aid_doctor_ref != 0)
						)
					) {
						this.majorFirstAdiResuscitation = true;

						var selectedDateTimeNew = this.checkoutForm.value.latestDate.year+"/"+this.checkoutForm.value.latestDate.month+"/"+this.checkoutForm.value.latestDate.day+" 00:00:00";
						var timeToRemoveNew = moment.duration("10:00:00");
						var dateToPassNew = moment(selectedDateTimeNew);
						dateToPassNew.subtract(timeToRemoveNew);
						var createdDateNewStart = moment(dateToPassNew).format("yyyy-MM-DDTHH:mm:ss")+".000Z";
						var createdDateNewEnd = moment.utc(createdDateNewStart).add(moment.duration(24, "hours")).format("yyyy-MM-DDTHH:mm:ss")+".000Z";

						var irdataset = localStorage.getItem('irdataset');
						if (typeof(irdataset) != 'undefined' && irdataset != null) {
							this.showIncidentReportButton = true;
							this.apiService.fetchIncidentReports(this.towerid, createdDateNewStart, createdDateNewEnd, irdataset).subscribe(res11 => {
								// Open Modal alert if no incident reports
								if(this.majorFirstAdiResuscitation && (res11.value.count == 0 || res11.value.count == undefined)) {
									this.openModal();
								}
							}, (e) => {
								console.log(e);
							});
						} else {
							this.showIncidentReportButton = false;
						}
					}
					// This is for Incident Report Alert

				}, (e) => {
					this.toastr.error('Failed', 'Invalid Beach Report data!');
				});
			} else {
				this.toastr.error('Failed', 'Beach Report & Incident Report are not configured to this tower !');
			}
			// AdminRole

		} else {

			// Check the Role Permission
			var createdDate1 = moment.utc(createdDate).tz("Australia/Brisbane").format("yyyy-MM-DDTHH:mm:ss")+".000Z";
			var currentDateTime1 = new Date();
			var aestCreatedDate1 = moment.utc(currentDateTime1).tz("Australia/Brisbane").format("yyyy-MM-DDTHH:mm:ss.000Z");
			var duration = moment(aestCreatedDate1).diff(moment.utc(createdDate1), 'days');
			// console.log(duration);

			var brdataset = localStorage.getItem('brdataset');
			if(duration <= 2) {
				// UserRole
				var brdataset = localStorage.getItem('brdataset');
				if (typeof(brdataset) != 'undefined' && brdataset != null) {
					this.showFormSubmissionLoader = true;
					this.apiService.postBeachReport(formData, createdDate, brdataset, 'beachreport').subscribe(res => {
						this.showFormSubmissionLoader = false;
						this.toastr.success('Success', 'Beach Report created successfully!');

						// This is for Incident Report Alert
						if (
							(
								(typeof(this.checkoutForm.value.theraphy) != 'undefined' && this.checkoutForm.value.theraphy != null && this.checkoutForm.value.theraphy != 0) ||
								(typeof(this.checkoutForm.value.surf_related_cpr) != 'undefined' && this.checkoutForm.value.surf_related_cpr != null && this.checkoutForm.value.surf_related_cpr != 0) ||
								(typeof(this.checkoutForm.value.other_related_cpr) != 'undefined' && this.checkoutForm.value.other_related_cpr != null && this.checkoutForm.value.other_related_cpr != 0) ||
								(typeof(this.checkoutForm.value.defib) != 'undefined' && this.checkoutForm.value.defib != null && this.checkoutForm.value.defib != 0)
							)
							||
							(
								(typeof(this.checkoutForm.value.fractures) != 'undefined' && this.checkoutForm.value.fractures != null && this.checkoutForm.value.fractures != 0) ||
								(typeof(this.checkoutForm.value.spinal) != 'undefined' && this.checkoutForm.value.spinal != null && this.checkoutForm.value.spinal != 0) ||
								(typeof(this.checkoutForm.value.dislocation) != 'undefined' && this.checkoutForm.value.dislocation != null && this.checkoutForm.value.dislocation != 0) ||
								(typeof(this.checkoutForm.value.fa_medical_aid_hospital) != 'undefined' && this.checkoutForm.value.fa_medical_aid_hospital != null && this.checkoutForm.value.fa_medical_aid_hospital != 0) ||
								(typeof(this.checkoutForm.value.fa_medical_aid_doctor_ref) != 'undefined' && this.checkoutForm.value.fa_medical_aid_doctor_ref != null && this.checkoutForm.value.fa_medical_aid_doctor_ref != 0)
							)
						) {
							this.majorFirstAdiResuscitation = true;

							var selectedDateTimeNew = this.checkoutForm.value.latestDate.year+"/"+this.checkoutForm.value.latestDate.month+"/"+this.checkoutForm.value.latestDate.day+" 00:00:00";
							var timeToRemoveNew = moment.duration("10:00:00");
							var dateToPassNew = moment(selectedDateTimeNew);
							dateToPassNew.subtract(timeToRemoveNew);
							var createdDateNewStart = moment(dateToPassNew).format("yyyy-MM-DDTHH:mm:ss")+".000Z";
							var createdDateNewEnd = moment.utc(createdDateNewStart).add(moment.duration(24, "hours")).format("yyyy-MM-DDTHH:mm:ss")+".000Z";

							var irdataset = localStorage.getItem('irdataset');
							if (typeof(irdataset) != 'undefined' && irdataset != null) {
								this.showIncidentReportButton = true;
								this.apiService.fetchIncidentReports(this.towerid, createdDateNewStart, createdDateNewEnd, irdataset).subscribe(res11 => {
									// Open Modal alert if no incident reports
									if(this.majorFirstAdiResuscitation && (res11.value.count == 0 || res11.value.count == undefined)) {
										this.openModal();
									}
								}, (e) => {
									console.log(e);
								});
							} else {
								this.showIncidentReportButton = false;
							}
						}
						// This is for Incident Report Alert

					}, (e) => {
						this.toastr.error('Failed', 'Invalid Beach Report data!');
					});
				} else {
					this.toastr.error('Failed', 'Beach Report & Incident Report are not configured to this tower !');
				}
				// UserRole

			} else {
				this.toastr.error('Failed', 'Sorry, you do not have permission to edit this record. Please contact your system administrator');
				return false;
			}
			// Check the Role Permission
		}
	 }

	 onConditionReportSubmit() {

		 var currentDateTime = new Date();
		 var selectedDateTime = this.checkoutForm.value.latestDate.year+"/"+this.checkoutForm.value.latestDate.month+"/"+this.checkoutForm.value.latestDate.day+" "+currentDateTime.getHours()+":"+currentDateTime.getMinutes()+":"+currentDateTime.getSeconds();
		 var timeToRemove = moment.duration("10:00:00");
		 var dateToPass = moment(selectedDateTime);
		 dateToPass.subtract(timeToRemove);
		 var createdDate = moment(dateToPass).format("yyyy-MM-DDTHH:mm:ss")+".000Z";

		 var towerIdFromString = this.processTowerIdFromName(this.checkoutForm.value.towerid);
		 // "Tower Id": this.checkoutForm.value.towerid,

		 this.requestConditionJson = {
			 "Surf Conditions": this.checkoutForm.value.surf_conditions,
			 "Swell Size": this.checkoutForm.value.swell_size,
			 "Weather": this.checkoutForm.value.weather,
			 "Wind Strength": this.checkoutForm.value.wind_strength,
			 "Wind Direction": this.checkoutForm.value.wind_direction,
			 "Beach Width": this.checkoutForm.value.beach_width,
			 "Water Quality": this.checkoutForm.value.water_quality,
			 "Tower Id": towerIdFromString,
		 };

		 const formData: FormData = new FormData();
		 formData.append('jsondata', JSON.stringify(this.requestConditionJson));

		 let adminRoleStatus = this.getUserRole();

		if(adminRoleStatus) {
			var codataset = localStorage.getItem('codataset');
			if (typeof(codataset) != 'undefined' && codataset != null) {
				this.apiService.postConditionReport(formData, createdDate, codataset).subscribe(res => {
					this.toastr.success('Success', 'Conditions Report created successfully!');

					$('#surf_conditions').val("");
					$('#swell_size').val(null);
					$('#weather').val("");
					$('#wind_strength').val("");
					$('#wind_direction').val("");
					$('#beach_width').val("");
					$('#water_quality').val("");

				}, (e) => {
					this.toastr.error('Failed', 'Invalid Conditions Report data!');
				});
			} else {
				this.toastr.error('Failed', 'Conditions Report are not configured to this tower !');
			}
		} else {

			var selectedDateTimeSpecificDate = this.checkoutForm.value.latestDate.year+"/"+this.checkoutForm.value.latestDate.month+"/"+this.checkoutForm.value.latestDate.day+" 00:00:00";
			var timeToRemoveSpecificHours = moment.duration("10:00:00");
			var dateToPassSpecific = moment(selectedDateTimeSpecificDate);
			dateToPassSpecific.subtract(timeToRemoveSpecificHours);
			var createdDateAsPerZone = moment(dateToPassSpecific).format("yyyy-MM-DDTHH:mm:ss")+".000Z";

			// For Role - User Only
			var codataset = localStorage.getItem('codataset');
			var createdDate1 = moment.utc(createdDateAsPerZone).tz("Australia/Brisbane").format("yyyy-MM-DDTHH:mm:ss")+".000Z";
			var currentDateTime1 = new Date();
			var aestCreatedDate1 = moment.utc(currentDateTime1).tz("Australia/Brisbane").format("yyyy-MM-DDTHH:mm:ss.000Z");
			var duration = moment(aestCreatedDate1).diff(moment.utc(createdDate1), 'days');
			// console.log(duration);

			if(duration <= 2) {
				if (typeof(codataset) != 'undefined' && codataset != null) {
					this.apiService.postConditionReport(formData, createdDate, codataset).subscribe(res => {
						this.toastr.success('Success', 'Conditions Report created successfully!');

						$('#surf_conditions').val("");
						$('#swell_size').val(null);
						$('#weather').val("");
						$('#wind_strength').val("");
						$('#wind_direction').val("");
						$('#beach_width').val("");
						$('#water_quality').val("");

					}, (e) => {
						this.toastr.error('Failed', 'Invalid Conditions Report data!');
					});
				} else {
					this.toastr.error('Failed', 'Conditions Report are not configured to this tower !');
				}
			} else {
				this.toastr.error('Failed', 'Sorry, you do not have permission to edit this record. Please contact your system administrator');
				return false;
			}
		}

	 }

	 onEquipmentReportSubmit() {

		 var currentDateTime = new Date();
		 var selectedDateTime = this.checkoutForm.value.latestDate.year+"/"+this.checkoutForm.value.latestDate.month+"/"+this.checkoutForm.value.latestDate.day+" "+currentDateTime.getHours()+":"+currentDateTime.getMinutes()+":"+currentDateTime.getSeconds();
		 var timeToRemove = moment.duration("10:00:00");
		 var dateToPass = moment(selectedDateTime);
		 dateToPass.subtract(timeToRemove);
		 var createdDate = moment(dateToPass).format("yyyy-MM-DDTHH:mm:ss")+".000Z";

		 var towerIdFromString = this.processTowerIdFromName(this.checkoutForm.value.towerid);
		 // "Tower Id": this.checkoutForm.value.towerid,

		 this.requestEquipmentJson = {
			 "Name On Staff": this.checkoutForm.value.name_on_staff_am,
			 "Equipment Notes": this.checkoutForm.value.equipment_notes,
			 "Board": (this.checkoutForm.value.board_am) ? 1 : 0,
			 "Tube": (this.checkoutForm.value.tube_am) ? 1 : 0,
			 "Hand Held": (this.checkoutForm.value.hand_held_am) ? 1 : 0,
			 "Vehicle Atv": (this.checkoutForm.value.vehicle_atv_am) ? 1 : 0,
			 "Tower": (this.checkoutForm.value.tower_am) ? 1 : 0,
			 "Pa Tower": (this.checkoutForm.value.pa_tower_am) ? 1 : 0,
			 "Pa Vehicle Atv": (this.checkoutForm.value.pa_vehicle_atv_am) ? 1 : 0,
			 "First Aid Kit": (this.checkoutForm.value.first_aid_kit_am) ? 1 : 0,
			 "Oxgen": (this.checkoutForm.value.oxgen_am) ? 1 : 0,
			 "Defib": (this.checkoutForm.value.defib_am) ? 1 : 0,
			 "Vehicle Check": (this.checkoutForm.value.vehicle_check_am) ? 1 : 0,
			 "Tower Id": towerIdFromString,
		 };

		 const formData: FormData = new FormData();
		 formData.append('jsondata', JSON.stringify(this.requestEquipmentJson));

		let adminRoleStatus = this.getUserRole();

		if(adminRoleStatus) {

			var eqdataset = localStorage.getItem('eqdataset');
			if (typeof(eqdataset) != 'undefined' && eqdataset != null) {
				this.apiService.postEquipmentReport(formData, createdDate, eqdataset).subscribe(res => {
					this.toastr.success('Success', 'Equipment Report created successfully!');

					$('#name_on_staff_am').val("");
					$('#equipment_notes').val("");
					$('#board_am').prop('checked', false);
					$('#tube_am').prop('checked', false);
					$('#hand_held_am').prop('checked', false);
					$('#vehicle_atv_am').prop('checked', false);
					$('#tower_am').prop('checked', false);
					$('#pa_tower_am').prop('checked', false);
					$('#pa_vehicle_atv_am').prop('checked', false);
					$('#first_aid_kit_am').prop('checked', false);
					$('#oxgen_am').prop('checked', false);
					$('#defib_am').prop('checked', false);
					$('#vehicle_check_am').prop('checked', false);

				}, (e) => {
					this.toastr.error('Failed', 'Invalid Equipment Report data!');
				});
			} else {
				this.toastr.error('Failed', 'Equipment Report id not configured to this tower !');
			}
		} else {

			var selectedDateTimeSpecificDate = this.checkoutForm.value.latestDate.year+"/"+this.checkoutForm.value.latestDate.month+"/"+this.checkoutForm.value.latestDate.day+" 00:00:00";
			var timeToRemoveSpecificHours = moment.duration("10:00:00");
			var dateToPassSpecific = moment(selectedDateTimeSpecificDate);
			dateToPassSpecific.subtract(timeToRemoveSpecificHours);
			var createdDateAsPerZone = moment(dateToPassSpecific).format("yyyy-MM-DDTHH:mm:ss")+".000Z";

			// For Role - User Only
			var eqdataset = localStorage.getItem('eqdataset');
			var createdDate1 = moment.utc(createdDateAsPerZone).tz("Australia/Brisbane").format("yyyy-MM-DDTHH:mm:ss")+".000Z";
			var currentDateTime1 = new Date();
			var aestCreatedDate1 = moment.utc(currentDateTime1).tz("Australia/Brisbane").format("yyyy-MM-DDTHH:mm:ss.000Z");
			var duration = moment(aestCreatedDate1).diff(moment.utc(createdDate1), 'days');
			// console.log(duration);

			if(duration <= 2) {

				this.apiService.postEquipmentReport(formData, createdDate, eqdataset).subscribe(res => {
					this.toastr.success('Success', 'Equipment Report created successfully!');

					$('#name_on_staff_am').val("");
					$('#equipment_notes').val("");
					$('#board_am').prop('checked', false);
					$('#tube_am').prop('checked', false);
					$('#hand_held_am').prop('checked', false);
					$('#vehicle_atv_am').prop('checked', false);
					$('#tower_am').prop('checked', false);
					$('#pa_tower_am').prop('checked', false);
					$('#pa_vehicle_atv_am').prop('checked', false);
					$('#first_aid_kit_am').prop('checked', false);
					$('#oxgen_am').prop('checked', false);
					$('#defib_am').prop('checked', false);
					$('#vehicle_check_am').prop('checked', false);

				}, (e) => {
					this.toastr.error('Failed', 'Invalid Equipment Report data!');
				});

			} else {
				this.toastr.error('Failed', 'Sorry, you do not have permission to edit this record. Please contact your system administrator');
				return false;
			}
			// For Role - User Only
		}
	 }

	 // For Flag Placement File Upload
	 selectFile1(event) {
		 this.selectedFiles1 = event.target.files;

		 var reader = new FileReader();
		 reader.onload = function(e) {
			/* $('#video-upload-wrap-id').hide();
			 $('#video-upload-image-id').attr('src', URL.createObjectURL(event.target.files[0]));
			 $('#video-upload-image-id').attr('alt', event.target.files[0].name);
			 $('#video-upload-content-id').show();

			 $('#image-title-id').html(event.target.files[0].name);*/

			$('#video-upload-wrap-id').hide();
			$('#video-upload-content-id').show();
			$('#video-upload-image-id').show();
			$('#video-upload-video-id').show();

			if(event.target.files[0].name.includes("mp4")) {
				$('#video-upload-video-id').attr('src', URL.createObjectURL(event.target.files[0]));
				$('#video-upload-video-id').attr('alt', event.target.files[0].name);

				$('#video-upload-image-id').hide();
			} else {

				$('#video-upload-image-id').attr('src', URL.createObjectURL(event.target.files[0]));
				$('#video-upload-image-id').attr('alt', event.target.files[0].name);

				$('#video-upload-video-id').hide();
			}
			$('#video-upload-content-id').show();

			$('#image-title-id').html(event.target.files[0].name);
		 };
		 reader.readAsDataURL(event.target.files[0]);
	 }

	 removeUpload1() {
		if(confirm("Are you sure to remove this Media file?")) {
		 $('#flag_placement').replaceWith($('#flag_placement').clone());
		 $('#video-upload-content-id').hide();
		 $('#video-upload-wrap-id').show();

		 $('#flag_placement').val('');
		 this.flagPlacementImagePath = null;
		}
	 }


	 // For Information Sign File Upload
	 selectFile2(event) {
		 this.selectedFiles2 = event.target.files;

		 var reader = new FileReader();
		 reader.onload = function(e) {
			 /*$('#video-upload-wrap-id1').hide();
			 $('#video-upload-image-id1').attr('src', URL.createObjectURL(event.target.files[0]));
			 $('#video-upload-image-id1').attr('alt', event.target.files[0].name);
			 $('#video-upload-content-id1').show();

			 $('#image-title-id1').html(event.target.files[0].name);*/

			$('#video-upload-wrap-id1').hide();

			$('#video-upload-image-id1').show();
			$('#video-upload-video-id1').show();

			if(event.target.files[0].name.includes("mp4")) {
				$('#video-upload-video-id1').attr('src', URL.createObjectURL(event.target.files[0]));
				$('#video-upload-video-id1').attr('alt', event.target.files[0].name);

				$('#video-upload-image-id1').hide();
			} else {

				$('#video-upload-image-id1').attr('src', URL.createObjectURL(event.target.files[0]));
				$('#video-upload-image-id1').attr('alt', event.target.files[0].name);

				$('#video-upload-video-id1').hide();
			}

			$('#video-upload-content-id1').show();

			$('#image-title-id1').html(event.target.files[0].name);
		 };
		 reader.readAsDataURL(event.target.files[0]);
	 }

	 removeUpload2() {
		if(confirm("Are you sure to remove this Media file?")) {
		 $('#information_sign').replaceWith($('#information_sign').clone());
		 $('#video-upload-content-id1').hide();
		 $('#video-upload-wrap-id1').show();

		 $('#information_sign').val('');
		 this.informationSignImagePath = null;
		}
	 }

	 fetchDataset(towerid) {

		 localStorage.setItem('selectedtowerid', this.towerid+"");
		 localStorage.setItem('selecteddate', JSON.stringify(this.latestDate));

		 localStorage.removeItem('brdataset');
		 localStorage.removeItem('irdataset');
		 localStorage.removeItem('codataset');
		 localStorage.removeItem('eqdataset');

		/* this.apiService.fetchDatasetByTowerId().subscribe(res => {
			 for(var i=0;i<res.value.results.length;i++) {
				 // if(res.value.results[i].location == towerid && res.value.results[i].name === LIFEGUARD_BEACH_DATA) {
				 if(res.value.results[i].location == towerid &&
					 res.value.results[i].name.toLowerCase().includes(LIFEGUARD_BEACH_DATA.toLowerCase())) {
					 localStorage.setItem('brdataset', res.value.results[i].id+"");
				 }

				 // if(res.value.results[i].location == towerid && res.value.results[i].name === LIFEGUARD_INCIDENT_DATA) {
				 if(res.value.results[i].location == towerid &&
					 res.value.results[i].name.toLowerCase().includes(LIFEGUARD_INCIDENT_DATA.toLowerCase())) {
					 localStorage.setItem('irdataset', res.value.results[i].id+"");
				 }

				 // if(res.value.results[i].location == towerid && res.value.results[i].name === LIFEGUARD_CONDITIONS_DATA) {
				 if(res.value.results[i].location == towerid &&
					 res.value.results[i].name.toLowerCase().includes(LIFEGUARD_CONDITIONS_DATA.toLowerCase())) {
					 localStorage.setItem('codataset', res.value.results[i].id+"");
				 }

				 // if(res.value.results[i].location == towerid && res.value.results[i].name === LIFEGUARD_EQUIPMENT_DATA) {
				 if(res.value.results[i].location == towerid &&
					 res.value.results[i].name.toLowerCase().includes(LIFEGUARD_EQUIPMENT_DATA.toLowerCase())) {
					 localStorage.setItem('eqdataset', res.value.results[i].id+"");
				 }
			 }
			 this.dataReload();
			 this.router.navigate(['/add']);
		 }, (e) => {
			 console.log(e);
		 }); */


		var beachApi = false;
		var incidentApi = false;
		var conditionApi = false;
		var equipmentApi = false;

		// Bach
		this.apiService.fetchDatasetByTowerIdAndLocation(towerid, "beach").subscribe(res => {
			for(var i=0;i<res.value.results.length;i++) {
				if(res.value.results[i].location == towerid &&
					res.value.results[i].name.toLowerCase().includes(APP_SETTINGS.LIFEGUARD_BEACH_DATA.toLowerCase())) {
					localStorage.setItem('brdataset', res.value.results[i].id+"");
				}
			}
			beachApi = true;
		}, (e) => {
			console.log(e);
		});

		// Incident
		this.apiService.fetchDatasetByTowerIdAndLocation(towerid, "incident").subscribe(res => {
			for(var i=0;i<res.value.results.length;i++) {
				if(res.value.results[i].location == towerid &&
					res.value.results[i].name.toLowerCase().includes(APP_SETTINGS.LIFEGUARD_INCIDENT_DATA.toLowerCase())) {
					localStorage.setItem('irdataset', res.value.results[i].id+"");
				}
			}
			incidentApi = true;
		}, (e) => {
			console.log(e);
		});

		// Conditions
		this.apiService.fetchDatasetByTowerIdAndLocation(towerid, "condition").subscribe(res => {
			for(var i=0;i<res.value.results.length;i++) {
				if(res.value.results[i].location == towerid &&
					res.value.results[i].name.toLowerCase().includes(APP_SETTINGS.LIFEGUARD_CONDITIONS_DATA.toLowerCase())) {
					localStorage.setItem('codataset', res.value.results[i].id+"");
				}
			}
			conditionApi = true;
		}, (e) => {
			console.log(e);
		});

		// Equipment
		this.apiService.fetchDatasetByTowerIdAndLocation(towerid, "equipment").subscribe(res => {
			for(var i=0;i<res.value.results.length;i++) {
				if(res.value.results[i].location == towerid &&
					res.value.results[i].name.toLowerCase().includes(APP_SETTINGS.LIFEGUARD_EQUIPMENT_DATA.toLowerCase())) {
					localStorage.setItem('eqdataset', res.value.results[i].id+"");
				}
			}
			equipmentApi = true;
		}, (e) => {
			console.log(e);
		});

		// After all load
		if(beachApi && incidentApi && conditionApi && equipmentApi) {
			this.dataReload();
			this.router.navigate(['/add']);
		}

		// Tempararly fix.
		this.router.navigate(['/']);
	 }

	 // Logout User
	 logOut() {
		 this.toastr.success('Success', "Logged Out Successfully");
		 this.apiService.clearSession();
		 this.router.navigate(['/login']);
	 }

	 viewIncidentReport(record) {
		 localStorage.setItem('irdata', JSON.stringify(record));
		 this.router.navigate(['/detail/1']);
	 }

	 removeSelectedIncidetReport() {
		 localStorage.removeItem('irdata');
	 }

	 latestDateChanged(changes: KeyValueChanges<string, any>) {
		 this.dataReload();
	   }

	 ngDoCheck(): void {
		 const changes = this.latestDateDiffer.diff(this.latestDate);
		 if (changes) {
		   this.latestDateChanged(changes);
		 }
	 }

	 dataReload() {
		localStorage.setItem('selecteddate', JSON.stringify(this.latestDate));
		localStorage.setItem('selectedtowerid', this.towerid+"");

		 this.equipmentLastSubmission = "NA";
		 this.conditionLastSubmission = "NA";

		 // Default Values before loading actual data.
		 this.checkoutForm = this.formBuilder.group({
			 date : this.date,
			 towerid: this.towerid,
			 towersList: this.towersList,
			 latestDate: this.latestDate,
			 roastered_staff: '',
			 beach_status: "closed",
			 swim_9am: null,
			 swim_12pm: null,
			 swim_3pm: null,
			 craft_9am: null,
			 craft_12pm: null,
			 craft_3pm: null,
			 beach_9am: null,
			 beach_12pm: null,
			 beach_3pm: null,
			 board: 0,
			 tube: 0,
			 swim: 0,
			 ski: 0,
			 call_outs: 0,
			 call_outs_after_hours: 0,
			 medical_aid_hospital: 0,
			 medical_aid_doctor_ref: 0,
			 marine_strings: 0,
			 fractures: 0,
			 spinal: 0,
			 dislocation: 0,
			 surf_craft_injury: 0,
			 general: 0,
			 fa_medical_aid_hospital: 0,
			 fa_medical_aid_doctor_ref: 0,
			 theraphy: 0,
			 surf_related_cpr: 0,
			 other_related_cpr: 0,
			 defib: 0,
			 pa: 0,
			 danger_sign: 0,
			 marine_stringer_sign: 0,
			 dog_owner_advised: 0,
			 craft_advised: 0,
			 by_laws_other: 0,
			 public_relations: 0,
			 flag_placement: null,
			 information_sign: null,
			 day_notes: '',

			 surf_conditions: "",
			 swell_size: null,
			 weather: "",
			 wind_strength: "",
			 wind_direction: "",
			 beach_width: null,
			 water_quality: "",

			 name_on_staff_am: '',
			 equipment_notes: '',
			 board_am: false,
			 tube_am: false,
			 hand_held_am: false,
			 vehicle_atv_am: false,
			 tower_am: false,
			 pa_tower_am: false,
			 pa_vehicle_atv_am: false,
			 first_aid_kit_am: false,
			 oxgen_am: false,
			 defib_am: false,
			 vehicle_check_am: false,

		 });
		 $('#video-upload-wrap-id').show();
		 $('#video-upload-content-id').hide();

		 $('#video-upload-wrap-id1').show();
		 $('#video-upload-content-id1').hide();

		 // Fetch Existing data
		 /*var createdDate = this.checkoutForm.value.latestDate.year+"-"+
			 ('0' + this.checkoutForm.value.latestDate.month).slice(-2)+"-"+
			 ('0' + this.checkoutForm.value.latestDate.day).slice(-2)+"T14:00:00.000Z"; */

		var selectedDateTime = this.checkoutForm.value.latestDate.year+"/"+this.checkoutForm.value.latestDate.month+"/"+this.checkoutForm.value.latestDate.day+" 00:00:00";
		var timeToRemove = moment.duration("10:00:00");
		var dateToPass = moment(selectedDateTime);
		dateToPass.subtract(timeToRemove);
		var createdDate = moment(dateToPass).format("yyyy-MM-DDTHH:mm:ss")+".000Z";

		 var brdataset = localStorage.getItem('brdataset');
		 if (typeof(brdataset) != 'undefined' && brdataset != null) {
			 this.apiService.fetchBeachReport(this.towerid, createdDate, brdataset).subscribe(res => {
				 // console.log(JSON.stringify(res));
				 if(res.value.count > 0) {
					 var recId = res.value.results[0].id.id;

					 var files = res.value.results[0].files;

					 // Fields
					 this.apiService.fieldmappingOnBeachReport(brdataset).subscribe(resp => {

						 var	towerId = this.getValueFromList("Tower Id", resp.value);
						 var	createDate = this.getValueFromList("Create Date", resp.value);
						 var	beach9am = this.getValueFromList("Beach Open to 9am", resp.value);
						 var	beach12pm = this.getValueFromList("Beach 9am to 1pm", resp.value);
						 var	beach3pm = this.getValueFromList("Beach 1pm to Close", resp.value);
						 var	board = this.getValueFromList("Board", resp.value);
						 var	tube = this.getValueFromList("Tube", resp.value);
						 var	swim = this.getValueFromList("Swim", resp.value);
						 var	ski = this.getValueFromList("Ski", resp.value);
						 var	callouts = this.getValueFromList("Call outs", resp.value);
						 var	calloutsAfterHours = this.getValueFromList("Call outs After Hours", resp.value);
						 var	medicalAidHospital = this.getValueFromList("Medical Aid Hospital", resp.value);
						 var	roasteredStaff = this.getValueFromList("Rostered Staff", resp.value);
						 var	medicalAidDoctorRef = this.getValueFromList("Medical Aid Doctor Ref", resp.value);
						 var	marineStrings = this.getValueFromList("Marine Sting", resp.value);
						 var	fractures = this.getValueFromList("Fractures", resp.value);
						 var	spinal = this.getValueFromList("Spinal", resp.value);
						 var	dislocation = this.getValueFromList("Dislocation", resp.value);
						 var	surfCraftInjury = this.getValueFromList("Surf Craft Injury", resp.value);
						 var	general = this.getValueFromList("General", resp.value);
						 var	faMedicalAidHospital = this.getValueFromList("Fa Medical Aid Hospital", resp.value);
						 var	faMedicalAidDoctorRef = this.getValueFromList("Fa Medical Aid Doctor Ref", resp.value);
						 var	theraphy = this.getValueFromList("Therapy", resp.value);
						 var	beachStatus = this.getValueFromList("Beach Status", resp.value);
						 var	surfRelatedCpr = this.getValueFromList("Surf Related Cpr", resp.value);
						 var	otherRelatedCpr = this.getValueFromList("Other Related Cpr", resp.value);
						 var	defib = this.getValueFromList("Defib", resp.value);
						 var	pa = this.getValueFromList("Preventative Actions And Public Announcements", resp.value);
						 var	dangerSign = this.getValueFromList("Danger Sign", resp.value);
						 var	marineStringerSign = this.getValueFromList("Marine Stinger Sign", resp.value);
						 var	dogOwnerAdvised = this.getValueFromList("Dog Owner Advised", resp.value);
						 var	craftAdvised = this.getValueFromList("Craft Advised", resp.value);
						 var	byLawsOther = this.getValueFromList("By Laws Other", resp.value);
						 var	publicRelations = this.getValueFromList("Public Relations", resp.value);
						 var	swim9am = this.getValueFromList("Swim Open to 9am", resp.value);
						 var	flagPlacement = this.getValueFromList("Flag Placement", resp.value);
						 var	informationSign = this.getValueFromList("Information Sign", resp.value);
						 var	dayNotes = this.getValueFromList("Day Notes", resp.value);
						 var	swim12pm = this.getValueFromList("Swim 9am to 1pm", resp.value);
						 var	swim3pm = this.getValueFromList("Swim 1pm to Close", resp.value);
						 var	craft9am = this.getValueFromList("Craft Open to 9am", resp.value);
						 var	craft12pm = this.getValueFromList("Craft 9am to 1pm", resp.value);
						 var	craft3pm = this.getValueFromList("Craft 1pm to Close", resp.value);

						 // Equipment Dataset Data Load
						 var name_on_staff_am_value = '';
						 var equipment_notes_value = '';
						 var board_am_value = false;
						 var tube_am_value = false;
						 var hand_held_am_value = false;
						 var vehicle_atv_am_value = false;
						 var tower_am_value = false;
						 var pa_tower_am_value = false;
						 var pa_vehicle_atv_am_value = false;
						 var first_aid_kit_am_value = false;
						 var oxgen_am_value = false;
						 var defib_am_value = false;
						 var vehicle_check_am_value = false;

						 var eqdataset = localStorage.getItem('eqdataset');
						 this.equipmentLastSubmission = "NA";
						 if (typeof(eqdataset) != 'undefined' && eqdataset != null) {

							var createdDate2 = this.checkoutForm.value.latestDate.year+"-"+
								('0' + this.checkoutForm.value.latestDate.month).slice(-2)+"-"+
								('0' + this.checkoutForm.value.latestDate.day).slice(-2)+"T14:00:00.000Z";
								// createdDate to createdDate2 for last load

							 this.apiService.fetchEquipmentReport(this.towerid, createdDate2, eqdataset).subscribe(res => {
								 // console.log(JSON.stringify(res));
								 if(res.value.count > 0) {

									 if(res.value.results.length > 0) {
										 /*var datetimesplit = res.value.results[0].timestamp;
										 var currentDateTime = new Date(datetimesplit);
										 let createDateUTCFormat = new Date(Date.UTC(this.checkoutForm.value.latestDate.year, this.checkoutForm.value.latestDate.month-1, this.checkoutForm.value.latestDate.day, currentDateTime.getHours(), currentDateTime.getMinutes(), currentDateTime.getSeconds()));
										 let aestDatetime = createDateUTCFormat.toLocaleTimeString('en-AU', {timeZone: "Australia/Brisbane"});
										 this.equipmentLastSubmission = aestDatetime + " today";*/

										var datetimesplit = res.value.results[0].timestamp;
										var currentDateTime = new Date(datetimesplit).toUTCString();
										var aestTimestamp = moment.utc(datetimesplit).tz("Australia/Brisbane").format("DD MMM YYYY HH:mm A");
										// console.log(aestTimestamp);
										this.equipmentLastSubmission = aestTimestamp + " ";
									 }

									 var recId = res.value.results[0].id.id;
									 // Fields
									 this.apiService.fieldmappingOnBeachReport(eqdataset).subscribe(resp => {

										 var name_on_staff_am = this.getValueFromList("Name On Staff", resp.value);
										 var equipment_notes = this.getValueFromList("Equipment Notes", resp.value);
										 var board_am = this.getValueFromList("Board", resp.value);
										 var tube_am = this.getValueFromList("Tube", resp.value);
										 var hand_held_am = this.getValueFromList("Hand Held", resp.value);
										 var vehicle_atv_am = this.getValueFromList("Vehicle Atv", resp.value);
										 var tower_am = this.getValueFromList("Tower", resp.value);
										 var pa_tower_am = this.getValueFromList("Pa Tower", resp.value);
										 var pa_vehicle_atv_am = this.getValueFromList("Pa Vehicle Atv", resp.value);
										 var first_aid_kit_am = this.getValueFromList("First Aid Kit", resp.value);
										 var oxgen_am = this.getValueFromList("Oxgen", resp.value);
										 var defib_am = this.getValueFromList("Defib", resp.value);
										 var vehicle_check_am = this.getValueFromList("Vehicle Check", resp.value);
										 var towerIdeq = this.getValueFromList("Tower Id", resp.value);

										 for(var i=0;i<res.value.results.length;i++) {
											 var rrr = res.value.results[i].data;
											 for(var key in rrr) {
												 if(towerIdeq === key) {
													 var objectValue = rrr[key];

													 var towerIdFromString = this.processTowerIdFromName(this.towerid);

													 // if(objectValue === this.towerid) {
													 if(objectValue === towerIdFromString) {
														 // this.toastr.success('Equipment Record', 'Record is already exists!');

														 name_on_staff_am_value = rrr[name_on_staff_am];
														 equipment_notes_value = rrr[equipment_notes];
														 board_am_value = (rrr[board_am] === 1) ? true : false;
														 tube_am_value = (rrr[tube_am] === 1) ? true : false;
														 hand_held_am_value = (rrr[hand_held_am] === 1) ? true : false;
														 vehicle_atv_am_value = (rrr[vehicle_atv_am] === 1) ? true : false;
														 tower_am_value = (rrr[tower_am] === 1) ? true : false;
														 pa_tower_am_value = (rrr[pa_tower_am] === 1) ? true : false;
														 pa_vehicle_atv_am_value = (rrr[pa_vehicle_atv_am] === 1) ? true : false;
														 first_aid_kit_am_value = (rrr[first_aid_kit_am] === 1) ? true : false;
														 oxgen_am_value = (rrr[oxgen_am] === 1) ? true : false;
														 defib_am_value = (rrr[defib_am] === 1) ? true : false;
														 vehicle_check_am_value = (rrr[vehicle_check_am] === 1) ? true : false;

													 }
												 }
											 }
										 }
									 }, (e) => {
										 console.log(e);
									 });
								 }
							 }, (e) => {
								 console.log(e);
							 });
						 } else {
							 this.toastr.error('Failed', 'Please select tower / location !');
							 this.router.navigate(['/']);
						 }

						 // Condition Dataset Data Load
						 var proceedProcess = false;
						 var surf_conditions_value = "";
						 var swell_size_value = null;
						 var weather_value = "";
						 var wind_strength_value = "";
						 var wind_direction_value = null;
						 var beach_width_value = null;
						 var water_quality_value = "";

						 var codataset = localStorage.getItem('codataset');
						 this.conditionLastSubmission = "NA";
						 if (typeof(codataset) != 'undefined' && codataset != null) {

							var createdDate1 = this.checkoutForm.value.latestDate.year+"-"+
							('0' + this.checkoutForm.value.latestDate.month).slice(-2)+"-"+
							('0' + this.checkoutForm.value.latestDate.day).slice(-2)+"T14:00:00.000Z";
							// createdDate to createdDate1 for last load

							 this.apiService.fetchConditionReport(this.towerid, createdDate1, codataset).subscribe(res => {
								 // console.log(JSON.stringify(res));
								 if(res.value.count > 0) {

									 if(res.value.results.length > 0) {
										 /*var datetimesplit = res.value.results[0].timestamp;
										 var currentDateTime = new Date(datetimesplit);
										 let createDateUTCFormat = new Date(Date.UTC(this.checkoutForm.value.latestDate.year, this.checkoutForm.value.latestDate.month-1, this.checkoutForm.value.latestDate.day, currentDateTime.getHours(), currentDateTime.getMinutes(), currentDateTime.getSeconds()));
										 let aestDatetime = createDateUTCFormat.toLocaleTimeString('en-AU', {timeZone: "Australia/Brisbane"});
										 this.conditionLastSubmission = aestDatetime + " today";*/

										var datetimesplit = res.value.results[0].timestamp;
										var currentDateTime = new Date(datetimesplit).toUTCString();
										var aestTimestamp = moment.utc(datetimesplit).tz("Australia/Brisbane").format("DD MMM YYYY HH:mm A");
										// console.log(aestTimestamp);
										this.conditionLastSubmission = aestTimestamp + " ";
									 }

									 var recId = res.value.results[0].id.id;
									 // Fields
									 this.apiService.fieldmappingOnBeachReport(codataset).subscribe(resp => {

										 var towerId = this.getValueFromList("Tower Id", resp.value);
										 var surf_conditions = this.getValueFromList("Surf Conditions", resp.value);
										 var swell_size = this.getValueFromList("Swell Size", resp.value);
										 var weather = this.getValueFromList("Weather", resp.value);
										 var wind_strength = this.getValueFromList("Wind Strength", resp.value);
										 var wind_direction = this.getValueFromList("Wind Direction", resp.value);
										 var beach_width = this.getValueFromList("Beach Width", resp.value);
										 var water_quality = this.getValueFromList("Water Quality", resp.value);

										 for(var i=0;i<res.value.results.length;i++) {
											 var rr = res.value.results[i].data;
											 for(var key in rr) {
												 if(towerId === key) {
													 var objectValue = rr[key];

													 var towerIdFromString = this.processTowerIdFromName(this.towerid);

													 // if(objectValue === this.towerid) {
													 if(objectValue === towerIdFromString) {
														 // this.toastr.success('Conditions Record', 'Record is already exists!');

														 surf_conditions_value = rr[surf_conditions];
														 swell_size_value = rr[swell_size];
														 weather_value = rr[weather];
														 wind_strength_value = rr[wind_strength];
														 wind_direction_value = rr[wind_direction];
														 beach_width_value = rr[beach_width];
														 water_quality_value = rr[water_quality];

														 proceedProcess = true;
													 }
												 }
											 }
										 }
									 }, (e) => {
										 console.log(e);
									 });
								 }
							 }, (e) => {
								 console.log(e);
							 });
						 } else {
							 this.toastr.error('Failed', 'Please select tower / location !');
							 this.router.navigate(['/']);
						 }

						 if(true) {
							 for(var i=0;i<res.value.results.length;i++) {
								 var r = res.value.results[i].data;
								 for(var key in r) {
									 if(towerId === key) {
										 var objectValue = r[key];

										 var towerIdFromString = this.processTowerIdFromName(this.towerid);

										 // if(objectValue === this.towerid) {
										 if(objectValue === towerIdFromString) {
											 // this.toastr.success('Beach Report Record', 'Record is already exists!');

											 this.checkoutForm = this.formBuilder.group({
												 date : this.date,
												 towerid: this.towerid,
												 towersList: this.towersList,
												 latestDate: this.latestDate,
												 roastered_staff: r[roasteredStaff],
												 beach_status: (r[beachStatus] ===1) ? 'open' : 'closed',
												 swim_9am: r[swim9am],
												 swim_12pm: r[swim12pm],
												 swim_3pm: r[swim3pm],
												 craft_9am: r[craft9am],
												 craft_12pm: r[craft12pm],
												 craft_3pm: r[craft3pm],
												 beach_9am: r[beach9am],
												 beach_12pm: r[beach12pm],
												 beach_3pm: r[beach3pm],
												 board: r[board],
												 tube: r[tube],
												 swim: r[swim],
												 ski: r[ski],
												 call_outs: r[callouts],
												 call_outs_after_hours: r[calloutsAfterHours],
												 medical_aid_hospital: r[medicalAidHospital],
												 medical_aid_doctor_ref: r[medicalAidDoctorRef],
												 marine_strings: r[marineStrings],
												 fractures: r[fractures],
												 spinal: r[spinal],
												 dislocation: r[dislocation],
												 surf_craft_injury: r[surfCraftInjury],
												 general: r[general],
												 fa_medical_aid_hospital: r[faMedicalAidHospital],
												 fa_medical_aid_doctor_ref: r[faMedicalAidDoctorRef],
												 theraphy: r[theraphy],
												 surf_related_cpr: r[surfRelatedCpr],
												 other_related_cpr: r[otherRelatedCpr],
												 defib: r[defib],
												 pa: r[pa],
												 danger_sign: r[dangerSign],
												 marine_stringer_sign: r[marineStringerSign],
												 dog_owner_advised: r[dogOwnerAdvised],
												 craft_advised: r[craftAdvised],
												 by_laws_other: r[byLawsOther],
												 public_relations: r[publicRelations],
												 flag_placement: null,
												 information_sign: null,
												 day_notes: r[dayNotes],

												 surf_conditions: surf_conditions_value,
												 swell_size: swell_size_value,
												 weather: weather_value,
												 wind_strength: wind_strength_value,
												 wind_direction: wind_direction_value,
												 beach_width: beach_width_value,
												 water_quality: water_quality_value,

												 name_on_staff_am: name_on_staff_am_value,
												 equipment_notes: equipment_notes_value,
												 board_am: board_am_value,
												 tube_am: tube_am_value,
												 hand_held_am: hand_held_am_value,
												 vehicle_atv_am: vehicle_atv_am_value,
												 tower_am: tower_am_value,
												 pa_tower_am: pa_tower_am_value,
												 pa_vehicle_atv_am: pa_vehicle_atv_am_value,
												 first_aid_kit_am: first_aid_kit_am_value,
												 oxgen_am: oxgen_am_value,
												 defib_am: defib_am_value,
												 vehicle_check_am: vehicle_check_am_value,

											 });
										 }
									 }
								 }
							 }
						 }

						 /*
						 this.apiService.imageView(brdataset, flagPlacement, recId).subscribe(resp => {
							 this.flagPlacementImagePath = 'data:image/png;base64,'+resp;
							 $('#video-upload-wrap-id').hide();
							 $('#video-upload-image-id').attr('src', 'data:image/png;base64,'+resp);
							 $('#video-upload-content-id').show();

						 }, (e) => {
							 console.log(e);
						 });

						 this.apiService.imageView(brdataset, informationSign, recId).subscribe(resp => {
							 this.informationSignImagePath = 'data:image/png;base64,'+resp;
							 $('#video-upload-wrap-id1').hide();
							 $('#video-upload-image-id1').attr('src', 'data:image/png;base64,'+resp);
							 $('#video-upload-content-id1').show();
						 }, (e) => {
							 console.log(e);
						 });
 						*/

						 if(files[flagPlacement].contentType.includes("image")) {
							this.apiService.imageView(brdataset, flagPlacement, recId).subscribe(resp => {
								this.flagPlacementImagePath = 'data:image/png;base64,'+resp;
								$('#video-upload-wrap-id').hide();
								$('#video-upload-video-id').hide();
								$('#video-upload-video-id-f-m').hide();
								$('#video-upload-image-id').attr('src', 'data:image/png;base64,'+resp);
								$('#video-upload-image-id-f-m').attr('src', 'data:image/png;base64,'+resp);
								$('#video-upload-content-id').show();

							}, (e) => {
								console.log("FlagPlacement File Not Found");
							});
						} else {
							this.apiService.videoView(brdataset, flagPlacement, recId).subscribe(resp => {
								this.flagPlacementImagePath = 'data:video/mp4;base64,'+resp;
								$('#video-upload-wrap-id').hide();
								$('#video-upload-image-id').hide();
								$('#video-upload-image-id-f-m').hide();
								$('#video-upload-video-id').attr('src', 'data:video/mp4;base64,'+resp);
								$('#video-upload-video-id-f-m').attr('src', 'data:video/mp4;base64,'+resp);
								$('#video-upload-content-id').show();

							}, (e) => {
								console.log("FlagPlacement File Not Found");
							});
						}

						if(files[informationSign].contentType.includes("image")) {
							this.apiService.imageView(brdataset, informationSign, recId).subscribe(resp => {
								this.informationSignImagePath = 'data:image/png;base64,'+resp;
								$('#video-upload-wrap-id1').hide();
								$('#video-upload-video-id1').hide();
								$('#video-upload-video-id-f-m-1').hide();
								$('#video-upload-image-id1').attr('src', 'data:image/png;base64,'+resp);
								$('#video-upload-image-id-f-m-1').attr('src', 'data:image/png;base64,'+resp);
								$('#video-upload-content-id1').show();
							}, (e) => {
								console.log("InformationSign File Not Found");
							});
						} else {
							this.apiService.videoView(brdataset, informationSign, recId).subscribe(resp => {
								this.informationSignImagePath = 'data:video/mp4;base64,'+resp;
								$('#video-upload-wrap-id1').hide();
								$('#video-upload-image-id1').hide();
								$('#video-upload-image-id-f-m-1').hide();
								$('#video-upload-video-id1').attr('src', 'data:video/mp4;base64,'+resp);
								$('#video-upload-video-id-f-m-1').attr('src', 'data:video/mp4;base64,'+resp);
								$('#video-upload-content-id1').show();

							}, (e) => {
								console.log("InformationSign File Not Found");
							});
						}
					 }, (e) => {
						 console.log(e);
					 });

				 } else {
					this.recordCreateTime = '';
					this.toastr.info('Information', 'There is no data available for this date.', { timeOut: 5000 });
				 }

			 }, (e) => {
				 console.log(e);
			 });
		 } else {

			 this.checkoutForm = this.formBuilder.group({
				 date : this.date,
				 towerid: this.towerid,
				 towersList: this.towersList,
				 latestDate: this.latestDate,
				 roastered_staff: '',
				 beach_status: "closed",
				 swim_9am: null,
				 swim_12pm: null,
				 swim_3pm: null,
				 craft_9am: null,
				 craft_12pm: null,
				 craft_3pm: null,
				 beach_9am: null,
				 beach_12pm: null,
				 beach_3pm: null,
				 board: 0,
				 tube: 0,
				 swim: 0,
				 ski: 0,
				 call_outs: 0,
				 call_outs_after_hours: 0,
				 medical_aid_hospital: 0,
				 medical_aid_doctor_ref: 0,
				 marine_strings: 0,
				 fractures: 0,
				 spinal: 0,
				 dislocation: 0,
				 surf_craft_injury: 0,
				 general: 0,
				 fa_medical_aid_hospital: 0,
				 fa_medical_aid_doctor_ref: 0,
				 theraphy: 0,
				 surf_related_cpr: 0,
				 other_related_cpr: 0,
				 defib: 0,
				 pa: 0,
				 danger_sign: 0,
				 marine_stringer_sign: 0,
				 dog_owner_advised: 0,
				 craft_advised: 0,
				 by_laws_other: 0,
				 public_relations: 0,
				 flag_placement: null,
				 information_sign: null,
				 day_notes: '',

				 surf_conditions: "",
				 swell_size: null,
				 weather: "",
				 wind_strength: "",
				 wind_direction: "",
				 beach_width: null,
				 water_quality: "",

				 name_on_staff_am: '',
				 equipment_notes: '',
				 board_am: false,
				 tube_am: false,
				 hand_held_am: false,
				 vehicle_atv_am: false,
				 tower_am: false,
				 pa_tower_am: false,
				 pa_vehicle_atv_am: false,
				 first_aid_kit_am: false,
				 oxgen_am: false,
				 defib_am: false,
				 vehicle_check_am: false,

			 });
			 $('#video-upload-wrap-id').show();
			 $('#video-upload-content-id').hide();

			 $('#video-upload-wrap-id1').show();
			 $('#video-upload-content-id1').hide();

			 this.toastr.error('Failed', 'Data Templates are not configured for this tower !');
			 this.router.navigate(['/']);
		 }


		/* var startDate = this.checkoutForm.value.latestDate.year+"-"+
			 ('0' + this.checkoutForm.value.latestDate.month).slice(-2)+"-"+
			 ('0' + this.checkoutForm.value.latestDate.day).slice(-2)+"T00:00:01.000Z";

		 var endDate = this.checkoutForm.value.latestDate.year+"-"+
			 ('0' + this.checkoutForm.value.latestDate.month).slice(-2)+"-"+
			 ('0' + this.checkoutForm.value.latestDate.day).slice(-2)+"T23:59:59.000Z";  */

		// var selectedDateTime = this.checkoutForm.value.latestDate.year+"/"+this.checkoutForm.value.latestDate.month+"/"+this.checkoutForm.value.latestDate.day+" 00:00:00";
		// var timeToRemove = moment.duration("10:00:00");
		// var dateToPass = moment(selectedDateTime);
		// dateToPass.subtract(timeToRemove);

		var selectedDateTime = this.checkoutForm.value.latestDate.year+"/"+
			('0' + this.checkoutForm.value.latestDate.month).slice(-2)+"/"+
			('0' + this.checkoutForm.value.latestDate.day).slice(-2);
		var dateToPass = moment(selectedDateTime);

		var createdDate = moment(dateToPass).format("yyyy-MM-DD");
		var startDate = createdDate+"T00:00:01.000Z";
		var endDate = createdDate+"T23:59:59.000Z";

		// var selectedDateTimeSpecificDate = this.checkoutForm.value.latestDate.year+"/"+this.checkoutForm.value.latestDate.month+"/"+this.checkoutForm.value.latestDate.day+" 00:00:00";
		// var timeToRemoveSpecificHours = moment.duration("10:00:00");
		// var dateToPassSpecific = moment(selectedDateTimeSpecificDate);
		// dateToPassSpecific.subtract(timeToRemoveSpecificHours);
		// var createdDateAsPerZone = moment(dateToPassSpecific).format("yyyy-MM-DDTHH:mm:ss")+".000Z";
		// var createdDateZone = moment.utc(createdDateAsPerZone).tz("Australia/Brisbane").format("yyyy-MM-DD");

		/*var selectedDateTime = this.checkoutForm.value.latestDate.year+"/"+
			('0' + this.checkoutForm.value.latestDate.month).slice(-2)+"/"+
			('0' + this.checkoutForm.value.latestDate.day).slice(-2);
		var dateToPass = moment(selectedDateTime);
		var createdDate = moment(dateToPass).format("yyyy-MM-DD");
		var startDate = createdDate+"T00:00:01.000Z";
		var endDate = createdDate+"T23:59:59.000Z";*/

		// var startDate = createdDateZone+"T00:00:01.000Z";
		// var endDate = createdDateZone+"T23:59:59.000Z";

		var selectedDateTimeNew = this.checkoutForm.value.latestDate.year+"/"+this.checkoutForm.value.latestDate.month+"/"+this.checkoutForm.value.latestDate.day+" 00:00:00";
		var timeToRemoveNew = moment.duration("10:00:00");
		var dateToPassNew = moment(selectedDateTimeNew);
		dateToPassNew.subtract(timeToRemoveNew);
		var createdDateNewStart = moment(dateToPassNew).format("yyyy-MM-DDTHH:mm:ss")+".000Z";
		var createdDateNewEnd = moment.utc(createdDateNewStart).add(moment.duration(24, "hours")).format("yyyy-MM-DDTHH:mm:ss")+".000Z";

		// var startDatetimeZone = moment.utc(startDate).format("yyyy-MM-DDTHH:mm:ss")+".000Z";
		// var endDatetimeZone = moment.utc(endDate).format("yyyy-MM-DDTHH:mm:ss")+".000Z";

		 var irdataset = localStorage.getItem('irdataset');
		 this.incidentReports = [];
		 this.incidentReportsCount = 0;
		 if (typeof(irdataset) != 'undefined' && irdataset != null) {
			 this.showIncidentReportButton = true;
			 this.apiService.fetchIncidentReports(this.towerid, createdDateNewStart, createdDateNewEnd, irdataset).subscribe(res => {
				 if(res.value.count > 0) {
					 this.apiService.fieldmappingOnBeachReport(irdataset).subscribe(resp => {
						 this.incidentReports = res.value.results;
						 this.incidentReportsCount = res.value.results.length;
					 }, (e) => {
						 console.log(e);
					 });
				 }
			 }, (e) => {
				 console.log(e);
			 });
		 } else {
			 this.showIncidentReportButton = false;
		 }

		 localStorage.removeItem('irdata');
	 }

	 incrementValue(field) {
		 const control = this.checkoutForm.get(field);
		 const curValue = control.value || 0;
		 const valueToUpdate = Number(curValue) + 1;
		 control.setValue(valueToUpdate);
		 $('#'+field).val(valueToUpdate);
	 }

	 decrementValue(field) {
		 const control = this.checkoutForm.get(field);
		 const curValue = control.value || 0;
		 let valueToUpdate = Number(curValue) - 1;
		 if(valueToUpdate < 0) valueToUpdate = 0;
		 control.setValue(valueToUpdate);
		 $('#'+field).val(valueToUpdate);
	 }

	 /**
	  *
	  * @returns
	  */
	 getUserRole() {
		let adminRoleStatus = false;
		let userInfo = localStorage.getItem('userInfo');
		let groups = JSON.parse(userInfo).groups;
		let organizationToCheck = "Organisation "+APP_SETTINGS.ORGANIZATION_ID;
		for(var i=0;i<groups.length;i++) {
			if(groups[i].toLowerCase().includes(organizationToCheck.toLowerCase())) {
				if(groups[i].toLowerCase().includes("Administrator".toLowerCase()) ||
					groups[i].toLowerCase().includes("Admin".toLowerCase())) {
						adminRoleStatus = true;
				}
			}
		}

		return adminRoleStatus;
	 }

	 openModal(){
		this.modalService.open(this.content, { centered: true });
	  }

 }

 /**
  * Created By : Venkat Salagrama
  */
